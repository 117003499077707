<template>
  <div>
    <h1>Quiz Overview</h1>
    <asset-overview v-if="quizzesReady"
      asset-icon="fa-lightbulb"
      :assets="quizzes"
      :kable-headers="kableHeaders"
      :kable-rows="kableRows"
      :show-archived-assets="showArchived"
      @tablecallback="tableCallback"
      @archive-toggle="updateArchiveToggle"
    >
      <template #new-asset>
        <quiz-builder @update="getQuizzes"></quiz-builder>
      </template>
    </asset-overview>
  </div>
</template>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import ArchiveToastConfirmMixin from '../archive-toast-confirm-mixin';
import getIcon from '../../../../modules/kate-table-icons';
import AssetOverview from '../asset-overview.vue';
import QuizBuilder from './quiz_creation/quiz-builder.vue';

export default {
  components: {
    AssetOverview,
    QuizBuilder,
  },

  mixins: [ErrorMixin, ArchiveToastConfirmMixin],
  data() {
    return {
      quizzesReady: false,
      quizzes: [],
      showArchived: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getQuizzes();
  },

  watch: {
    quizzesReady() {
      if (this.quizzesReady) {
        this.$Loading.finish();
      } else {
        this.$Loading.start();
      }
    },
  },

  computed: {
    kableHeaders() {
      return {
        edit: {
          name: 'Edit',
          type: 'url',
        },
        name: {
          name: 'Title',
          filterable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
          filterable: true,
        },
        tags: {
          name: 'Tags',
          type: 'tags',
          filterable: true,
        },
        archive: {
          name: 'Archive',
          type: 'action',
          sortable: true,
        },
      };
    },

    kableRows() {
      return this.quizzes.map((quiz, index) => ({
        edit: {
          key: index,
          text: getIcon({ icon: 'edit' }),
          path: {
            name: 'edit_quiz',
            params: {
              quizId: quiz.id,
            },
          },
        },
        name: {
          key: index,
          text: quiz.name,
          path: {
            name: 'curriculum_asset_details',
            params: {
              assetId: quiz.id,
              assetType: 'quiz',
            },
          },
        },
        description: quiz.description,
        tags: {
          tags: quiz.tags || [],
          sortBy: quiz.tags ? [...quiz.tags].sort().join(' ') : '',
        },
        archive: {
          text: this.assetArchiveButton(quiz.is_active),
        },
        is_active: quiz.is_active,
        id: quiz.id,
      })).filter(quiz => (this.showArchived || quiz.is_active));
    },
  },

  methods: {
    updateArchiveToggle(val) {
      this.showArchived = val;
    },

    getQuizzes() {
      this.$logger.info('Getting quizzes');
      this.quizzesReady = false;
      this.$http.get('/api/curriculum/admin/quiz?active_only=false').then(result => {
        this.$logger.info('Got quizzes');
        this.quizzes = result.data.quizzes;
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error getting quizzes', undefined, err);
          this.showError(err);
        } else {
          this.$logger.warn('No quizzes found');
        }
      }).then(() => {
        this.quizzesReady = true;
      });
    },

    // Archiving Quizzes
    archiveCallback(quizId) {
      this.$logger.info('Archiving quiz', { quizId }, true);
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/quiz/${quizId}/archive`)
        .then(() => {
          this.$logger.info('Quizzes has been archived', { quizId });
          this.$ktoast.success('Quiz has been archived');
          this.getQuizzes();
        }).catch(err => {
          this.$logger.error('Error archiving quiz', { quizId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    unarchiveCallback(quizId) {
      this.$logger.info('Unarchiving quiz', { quizId }, true);
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/quiz/${quizId}/unarchive`)
        .then(() => {
          this.$logger.info('Quiz has been unarchived', { quizId });
          this.$ktoast.success('Quiz has been unarchived');
          this.getQuizzes();
        }).catch(err => {
          this.$logger.error('Error unarchiving quiz', { quizId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    tableCallback(key, index, row, header) {
      if (header === 'archive') {
        this.tableArchiveCallback(row);
      }
    },
  },
};
</script>
