<template>
  <div class="panel panel-default create-programme-blueprint">
    <div class="panel-body">
      <p class="create-form-instructions">
        Create an <i class="highlight">empty</i> {{blueprintType}} blueprint by specifying
        a name and a description. Once the blueprint has been created, you can add content
        to it by clicking the link for that blueprint in the table below.
      </p>
      <k-form-input
        label="Name"
        inputId="programme-name-input"
        :model-value="newBlueprintName"
        placeholder="Programme Name"
        :required="true"
        @update:model-value="handleValueUpdate('newBlueprintName', $event)"
        :showWarning="checkNameInUse"
        warningMessage="Name already in use"
        max-length="255">
      </k-form-input>

      <k-form-input
        label="Description"
        inputId="programme-description-input"
        type="textarea"
        :model-value="newBlueprintDescription"
        placeholder="A short description of the programme"
        @update:model-value="handleValueUpdate('newBlueprintDescription', $event)"
        max-length="1023">
      </k-form-input>

      <k-form-input
        label="Product"
        inputId="programme-product-input"
        type="component">
        <template #components>
          <k-dropdown
            v-model="newBlueprintProductID"
            :options="products">
          </k-dropdown>
          <label for="programme-reporting-input" class="label-checkbox">
            <input
              type="checkbox"
              id="programme-reporting-input"
              v-model="newBlueprintIncludeInReport">
            Include in reporting? If programme is included in reporting, learner data will be added to the Tableau dashboards
          </label>
        </template>
      </k-form-input>

      <k-form-input
        inputId="programme-tags-input"
        type="component"
        v-model="newBlueprintTags">
        <template #components>
          <word-tags
            textInputPlaceholder="Add a tag"
            v-model="newBlueprintTags"
            :tags="newBlueprintTags">
          </word-tags>
        </template>
      </k-form-input>

      <k-form-input
        label="Badges"
        inputId="programme-badge-input"
        type="component">
        <template #components>
          <achievement-list
            v-if="selectedBadges.length > 0"
            :achievements="selectedBadges">
          </achievement-list>
          <p v-else>
            There are currently no badges in this programme - click the button below to add some
          </p>
          <button class="btn btn-primary" @click="showBadgeModal = true">Add Badge</button>
          <select-badge-modal
            :show="showBadgeModal"
            @close="showBadgeModal = false"
            @choose="addBadge">
          </select-badge-modal>
        </template>
      </k-form-input>

      <k-form-input
        label="Certificates"
        inputId="programme-certificate-input"
        type="component">
        <template #components>
          <achievement-list
            v-if="certificateToBeAwarded"
            :achievements="certificateToBeAwarded">
          </achievement-list>
          <p v-else>
            There is currently no certificate to be awarded on completion of this blueprint - click the button below to select one
          </p>
          <button class="btn btn-primary" @click="showCertificateModal = true">{{ certificateBtnText }}</button>
          <select-certificate-modal
            :show="showCertificateModal"
            @close="showCertificateModal = false"
            @choose="selectCertificate">
          </select-certificate-modal>
        </template>
      </k-form-input>
      <button class="btn btn-success" @click="createEmptyProgrammeBlueprint" :disabled="!validName">Create Programme Blueprint</button>
    </div>
  </div>
</template>

<style scoped>
.create-programme-blueprint .panel-body {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: end;
}

.btn.btn-success {
  width: fit-content;
}

.label-checkbox {
  margin: 15px 0 0;
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.label-checkbox:hover {
  text-decoration: underline;
}

label > input {
  margin: 0;
}

</style>

<script>
import AchievementList from '../../achievements/achievement-list.vue';
import SelectBadgeModal from '../../achievements/select-badge-modal.vue';
import SelectCertificateModal from '../../achievements/select-certificate-modal.vue';
import KDropdown from '../../../components/k-dropdown.vue';
import KFormInput from '../../../components/k_forms/k-form-input.vue';
import WordTags from '../../components/word-tags.vue';

export default {
  components: {
    AchievementList,
    SelectBadgeModal,
    SelectCertificateModal,
    KDropdown,
    KFormInput,
    WordTags,
  },

  data() {
    return {
      newBlueprintName: '',
      newBlueprintDescription: '',
      newBlueprintProductID: null,
      newBlueprintIncludeInReport: false,
      selectedBadges: [],
      certificateToBeAwarded: undefined,
      showBadgeModal: false,
      showCertificateModal: false,
      newBlueprintTags: [],
    };
  },

  props: {
    products: {
      type: Array,
      required: true,
    },
    blueprintType: {
      type: String,
      required: true,
    },
    programmeBlueprints: {
      type: Array,
    },
  },

  computed: {
    validName() {
      return Boolean(this.newBlueprintName.trim().length > 0 && this.newBlueprintName.trim().length < 256 && !this.checkNameInUse);
    },

    certificateBtnText() {
      return this.certificateToBeAwarded ? 'Change Certificate' : 'Select Certificate';
    },

    checkNameInUse() {
      if (this.programmeBlueprints.some(blueprint => blueprint.name === this.newBlueprintName.trim())) {
        return true;
      }
      return false;
    },
  },

  methods: {
    handleValueUpdate(key, value) {
      this[key] = value;
    },

    createEmptyProgrammeBlueprint() {
      const createPayload = {
        name: this.newBlueprintName.trim(),
        description: this.newBlueprintDescription || null,
        product_id: this.newBlueprintProductID || null,
        include_in_reporting: this.newBlueprintIncludeInReport,
        badge_ids: this.selectedBadges.map(badge => badge.id),
        certificate_id: this.certificateToBeAwarded ? this.certificateToBeAwarded[0].id : null,
        tags: this.newBlueprintTags,
        module_blueprints: [],
      };
      this.$logger.info('Creating empty programme blueprint', { createPayload }, true);
      this.$http.post('/api/curriculum/blueprints/programmes', createPayload).then(() => {
        this.$logger.info('Successfully updated blueprint', { createPayload });
        this.$ktoast.success('Blueprint created');
        this.newBlueprintName = '';
        this.newBlueprintDescription = '';
        this.$emit('update');
      }).catch(err => {
        this.showError(err);
        this.$logger.error('Error creating blueprint', { createPayload }, err);
      });
    },

    addBadge(badge) {
      if (!this.selectedBadges.find(b => b.id === badge.id)) {
        this.selectedBadges.push(badge);
      }
    },

    selectCertificate(certificate) {
      this.certificateToBeAwarded = [certificate];
    },
  },
};
</script>
