<template>
  <div class="panel-body">
    <h2>Add Privileged User</h2>
    <form id="create-priv-user-form">
      <div class="fieldset-container">
        <div class="formfields">
          <fieldset class="form-entry form-required">
            <label for="name">Name</label>
              <input type="text" class="form-control" id="name" placeholder="Type name here..." v-model="formName" required>
          </fieldset>
          <fieldset class="form-entry form-required">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" placeholder="Type email address here..." v-model="formEmail" required>
          </fieldset>
        </div>
        <div class="mentorship">
          <fieldset>
            <label for="coach-acuity-link">
              Coach
              <k-tooltip class="admin-tooltip" text="Coaches can be assigned to students who will then be able to contact them via KATE">
                <sup><i class="fas fa-question-circle"></i></sup>
              </k-tooltip>
            </label>
            <k-toggle classId="coach-toggle" :round="true" v-model="isCoach" :disabled="existingUserIsCoach"></k-toggle>
            <input id="coach-acuity-link" v-if="isCoach && !existingUserIsCoach" type="text" class="form-control" placeholder="Acuity Link" v-model="coachAcuityLink">
          </fieldset>
          <fieldset>
            <label for="data-mentor-acuity-link">
              Data Mentor
              <k-tooltip class="admin-tooltip" text="Data Mentors can be assigned to students who will then be able to contact them via KATE">
                <sup><i class="fas fa-question-circle"></i></sup>
              </k-tooltip>
            </label>
            <k-toggle classId="data-mentor-toggle" :round="true" v-model="isDataMentor" :disabled="existingUserIsDataMentor"></k-toggle>
            <input id="data-mentor-acuity-link" v-if="isDataMentor && !existingUserIsDataMentor" type="text" class="form-control" placeholder="Acuity Link" v-model="dataMentorAcuityLink">
          </fieldset>
          <fieldset>
            <span>
              Trainer
              <k-tooltip class="admin-tooltip" text="Trainer can be assigned to on the release Calendar Event page">
                <sup><i class="fas fa-question-circle"></i></sup>
              </k-tooltip>
            </span>
            <k-toggle classId="trainer-toggle" :round="true" v-model="isTrainer" :disabled="existingUserIsTrainer"></k-toggle>
          </fieldset>
        </div>
      </div>
      <div class="roles-checklist">
        <label for="roles" class="form-required">Add Roles</label>
        <ul class="checkbox-list">
          <li v-for="role in sanitisedRoles" :key="role.id">
            <label class="k-custom-checkbox">
              <input type="checkbox" id="roles" class="hidden-checkbox"
                    :value="role.name"
                    v-model="userRoles"
                    required>
              <span class="k-custom-checkbox-box alt"></span>
              <k-tooltip :text="role.description" position="right">
                <span class="k-custom-checkbox-text">{{ role.prettyName }}</span>
              </k-tooltip>
            </label>
          </li>
        </ul>
      </div>
      <div class="submit-container">
        <span v-if="existingUserDetails" v-html="warningText" :class="{'has-roles-warning' : exisitingUserHasRoles}">
        </span>
        <button v-if="existingUserDetails && validName" class="btn btn-outlined" @click="resetForm">Clear</button>
        <button class="btn btn-primary" type="submit" @click="formSubmit" value="Add User" :disabled="exisitingUserHasRoles">Add user</button>
        <button class="btn user-search btn-default" @click.prevent="openUserSearch"><i class="fas fa-search"></i> Search for existing user</button>
      </div>
    </form>
    <user-search
        @close="closeUserSearch"
        :allowSelect="true"
        @select="populateDetailsForUser"
        :show="showUserSearch">
    </user-search>
  </div>
</template>

<style>
.mentorship .k-toggle {
  justify-content: unset;
}

.roles-checklist .checkbox-list li {
  width: fit-content;
}

.roles-checklist .checkbox-list .k-custom-checkbox {
  padding: 5px;
  text-transform: capitalize;
  position: unset;
}

.k-custom-checkbox-text {
  padding-right: 0.3em;
  color: var(--kate-type-primary);
}

.k-search-dropdown-adv-content {
  z-index: 999;
}

.submit-container span.has-roles-warning {
  width: fit-content;
  padding: 5px;
  border-radius: 4px;
  background: var(--kate-type-dark);
}

.submit-container span.has-roles-warning i {
  color: var(--kate-warning);
}

</style>

<style scoped>
#create-priv-user-form,
.mentorship fieldset {
  display: flex;
  flex-wrap: wrap;
}

#create-priv-user-form {
  row-gap: 50px;
  column-gap: 5%;
}

.fieldset-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 30vw;
}

.submit-container {
  display: flex;
  width: 100%;
  gap: 15px;
}

.submit-container span {
  display: block;
  margin-bottom: 15px;
}

.mentorship fieldset {
  justify-content: space-between;
  column-gap: 15px;
}

.mentorship fieldset input {
  flex: 1;
}

.mentorship label {
  width: 100%;
}

fieldset {
  margin-bottom: 15px;
}

fieldset:last-child {
  margin-bottom: 0;
}

i.fa-question-circle {
  color: var(--kate-primary);
}
</style>

<script>
import KToggle from '../../components/k-toggle.vue';
import KTooltip from '../../components/k-tooltip.vue';
import ErrorMixin from '../../mixins/error-mixins';
import UserSearch from '../components/user-search.vue';

const EMAIL_REGEX = /.+@.+\..+/;

export default {
  components: {
    'k-toggle': KToggle,
    'k-tooltip': KTooltip,
    UserSearch,
  },

  mixins: [ErrorMixin],

  props: {
    roles: {
      type: Array,
    },
  },

  data() {
    return {
      formName: '',
      formEmail: '',
      userRoles: [],
      coachAcuityLink: '',
      dataMentorAcuityLink: '',
      isCoach: false,
      isTrainer: false,
      isDataMentor: false,
      loading: false,
      showUserSearch: false,
      existingUserDetails: undefined,
    };
  },

  computed: {
    warningText() {
      if (this.existingUserIsCoach) {
        return 'User is already a Coach.';
      }
      if (this.existingUserIsTrainer) {
        return 'User is already a Trainer.';
      }
      if (this.existingUserIsDataMentor) {
        return 'User is already a Data Mentor.';
      }
      if (this.exisitingUserHasRoles) {
        return '<i class="fa-solid fa-triangle-exclamation"></i> User already has roles, you can edit them in the table below.';
      }
      return undefined;
    },

    existingUserIsCoach() {
      return Boolean(this.existingUserDetails?.is_coach);
    },

    existingUserIsTrainer() {
      return Boolean(this.existingUserDetails?.is_trainer);
    },

    existingUserIsDataMentor() {
      return Boolean(this.existingUserDetails?.is_data_mentor);
    },

    exisitingUserHasRoles() {
      return Boolean(this.existingUserDetails?.user_roles.length);
    },

    sanitisedRoles() {
      if (this.roles.length > 0) {
        return this.roles.map(x => ({ ...x, prettyName: x.name.replaceAll('_', ' ') }));
      }
      return [];
    },

    validEmail() {
      return EMAIL_REGEX.test(this.formEmail);
    },
    validName() {
      return this.formName.length > 1;
    },
    validRoles() {
      return this.userRoles.length > 0;
    },
    validForm() {
      return this.validRoles && this.validName && this.validEmail;
    },
  },

  methods: {
    populateDetailsForUser(id) {
      this.isCoach = false;
      this.isTrainer = false;
      this.isDataMentor = false;
      this.$logger.info('Getting user');
      this.$http.get(`/api/profile/users/${id}`).then(res => {
        this.$logger.info('Successfully retrieved privileged users');
        this.formEmail = res.data.email;
        this.formName = res.data.full_name;
        this.userRoles = res.data.user_roles;
        this.existingUserDetails = res.data;
        this.isCoach = res.data.is_coach;
        this.isTrainer = res.data.is_trainer;
        this.isDataMentor = res.data.is_data_mentor;
        this.closeUserSearch();
      }).catch(err => {
        this.$logger.autowarn('There was an error while attempting to retrieve user', undefined, err);
        this.showError(err);
      });
    },

    validRolesToast() {
      if (!this.validRoles && this.validEmail && this.validName) {
        this.$ktoast.warning('Please select at least one role');
      }
    },

    formSubmit() {
      if (!this.validForm) {
        return this.validRolesToast();
      }
      return this.createPrivilegedUser();
    },

    resetForm() {
      this.formName = '';
      this.formEmail = '';
      this.coachAcuityLink = '';
      this.dataMentorAcuityLink = '';
      this.userRoles = [];
      this.isCoach = false;
      this.isTrainer = false;
      this.isDataMentor = false;
      this.existingUserDetails = undefined;
    },

    createPrivilegedUser() {
      this.$emit('loading', true);
      this.$logger.info('Creating new privileged user', undefined, true);
      this.$http.post('/api/profile/user/privileged', {
        email: this.formEmail,
        full_name: this.formName,
        is_coach: this.isCoach,
        is_trainer: this.isTrainer,
        is_data_mentor: this.isDataMentor,
        coach_acuity_link: this.coachAcuityLink,
        data_mentor_acuity_link: this.dataMentorAcuityLink,
        roles: this.roles.filter(x => this.userRoles.includes(x.name)),
      }).then(() => {
        this.$logger.info('Succesfully created new admin user');
        this.$emit('user-created');
        this.resetForm();
      }).catch(err => {
        this.$logger.error('Error creating new admin user');
        this.showError(err);
      }).then(() => {
        this.$emit('loading', false);
      });
    },
    openUserSearch() {
      this.showUserSearch = true;
    },
    closeUserSearch() {
      this.showUserSearch = false;
    },
  },
};
</script>
