<template>
  <div>
    <h1>Manage Programme Blueprints</h1>
    <k-nav-tabs :currentTab="currentTab" :tabs="tabs"></k-nav-tabs>
    <router-view v-if="ready" v-bind="pageProps" @update="getProgrammeBlueprints"></router-view>
  </div>
</template>

<style>
td.k-table-key-delete {
  text-align: center;
}

td.k-table-key-delete i.fas.fa-trash-alt {
  color: var(--kate-danger);
}
</style>

<script>
import ErrorMixin from '../../../mixins/error-mixins';
import TimeMixin from '../../../mixins/time-mixins';
import PageReadyMixin from '../../../mixins/page-ready-mixin';
import KNavTabs from '../../../components/k-nav-tabs.vue';

export default {
  components: {
    KNavTabs,
  },

  mixins: [ErrorMixin, TimeMixin, PageReadyMixin],

  data() {
    return {
      tabs: [
        {
          name: 'Current',
          routeName: 'curriculum_blueprints_current_programmes',
        },
        {
          name: 'Create',
          routeName: 'curriculum_blueprints_create_programme',
        },
      ],

      programmeBlueprints: [],
      programmeBlueprintsReady: false,
      updateInProgress: false,
      newBlueprintDescription: '',
      newBlueprintProductID: undefined,
      newBlueprintIncludeInReport: false,
      products: [],
      productsReady: false,
      newBlueprintName: '',
      confirmDeletionToast: undefined,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgrammeBlueprints();
    this.getProducts();
  },

  computed: {
    currentTab() {
      return this.$route.name;
    },
    pageProps() {
      if (this.$route.name === 'curriculum_blueprints_create_programme') {
        return {
          products: this.products,
          blueprintType: 'programme',
          programmeBlueprints: this.programmeBlueprints,
        };
      }
      return {
        products: this.products,
        ready: this.ready,
        blueprintType: 'programme',
        kableRows: this.kableRows,
        kableHeaders: this.kableHeaders,
        tableCallback: this.tableCallback,
      };
    },

    ready() {
      return this.programmeBlueprintsReady && !this.updateInProgress;
    },
    kableHeaders() {
      return {
        name: {
          name: 'Name',
          filterable: true,
          sortable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
          filterable: true,
          sortable: true,
        },
        includeInReport: {
          name: 'Include in report',
        },
        productID: {
          name: 'Product',
        },
        knowledgeBase: {
          name: 'Knowledge Base',
          type: 'action',
          sortable: true,
        },
        kloud: {
          name: 'KLOUD',
          type: 'action',
          sortable: true,
        },
        delete: {
          name: 'Delete',
          type: 'action',
        },
      };
    },
    kableRows() {
      return this.programmeBlueprints.map((val, index) => ({
        programmeId: val.id,
        name: {
          text: val.name,
          path: { name: 'curriculum_manage_programme_blueprint', params: { programmeBlueprintId: val.id } },
        },
        description: val.description,
        includeInReport: val.include_in_reporting ? 'yes' : 'no',
        productID: this.productName(val.product_id),
        knowledgeBase: {
          key: index,
          enabled: val.enable_kb,
          text: this.enabledIcon(val.enable_kb, 'Knowledge Base'),
        },
        kloud: {
          key: index,
          enabled: val.enable_kloud,
          text: this.enabledIcon(val.enable_kloud, 'KLOUD'),
        },
        delete: {
          key: index,
          text: '<i class="fas fa-trash-alt"></i>',
        },
      }));
    },
  },

  methods: {
    getProgrammeBlueprints() {
      this.$logger.info('Getting programme blueprints');
      this.programmeBlueprintsReady = false;
      return this.$http.get('/api/curriculum/blueprints/programmes').then(res => {
        this.programmeBlueprints = res.data.blueprints;
        this.$logger.info('Successfully retrieved programme blueprints');
      }).catch(err => {
        this.$logger.error('Error retrieving programmes', undefined, err);
        this.showError(err);
      }).then(() => {
        this.programmeBlueprintsReady = true;
      });
    },
    confirmDeletion(programmeBlueprintToDelete) {
      this.$ktoast.confirmToast(
        `You are about to delete the programme blueprint ${programmeBlueprintToDelete.name}?`,
        'warning',
        () => {
          this.deleteProgrammeBlueprint(programmeBlueprintToDelete);
        },
      );
    },
    deleteProgrammeBlueprint(programmeBlueprintToDelete) {
      this.$logger.info('Deleting programme blueprint', { programmeBlueprintId: programmeBlueprintToDelete.id }, true);
      this.updateInProgress = true;
      return this.$http.delete(`/api/curriculum/blueprints/programmes/${programmeBlueprintToDelete.id}`).then(() => {
        this.$logger.info('Successfully deleted programme blueprint');
        this.$ktoast.success('Blueprint deleted');
        this.getProgrammeBlueprints();
      }).catch(err => {
        this.$logger.error('Error deleting programme blueprint', undefined, err);
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    tableCallback(key, index, row, header) {
      if (header === 'delete') {
        this.confirmDeletion(this.programmeBlueprints[index]);
      } else if (header === 'kloud' || header === 'knowledgeBase') {
        this.tableFeatureEnableCallback(row, header);
      }
    },
    enabledIcon(enabled, title) {
      if (enabled) {
        return `<div title="${title} enabled for programme. Click to disable." style="text-align: center;"><button class="btn btn-danger" title="Click to disable ${title} for programme">Disable</button></div>`;
      }
      return `<div title="${title} disabled for programme. Click to enable." style="text-align: center;"><button class="btn btn-success" title="Click to enable ${title} for programme">Enable</button></div>`;
    },
    getProducts() {
      this.$logger.info('Getting Product IDs');
      this.productsReady = false;
      return this.$http.get('/api/curriculum/products').then(res => {
        this.products = res.data;
      }).catch(err => {
        this.$logger.error('Getting Product IDS for New Programme Blueprints', undefined, err);
        this.showError(err);
      }).then(() => {
        this.productsReady = true;
      });
    },
    productName(prodId) {
      const prodIndex = this.products.findIndex(x => x.id === prodId);
      if (prodIndex < 0 || !prodId) {
        return null;
      }
      return this.products[prodIndex].name;
    },

    getProgrammeFeatureEnableToastMessage(row, featureEnabled) {
      const enabled = row[featureEnabled].enabled;
      const featureEnabledReadable = featureEnabled === 'kloud' ? 'KLOUD' : 'Knowledge Base';
      if (enabled) {
        return `${featureEnabledReadable} will be disabled for every module and assignment.`;
      }
      return `${featureEnabledReadable} will be enabled for every module and assignment.`;
    },

    enableFeatureInProgramme(programmeId, featureEnabled, enabled) {
      const enablePref = enabled ? 'dis' : 'en';
      const featureEnabledURL = featureEnabled === 'knowledgeBase' ? 'knowledge-base' : featureEnabled;
      const featureEnabledReadable = featureEnabled === 'kloud' ? 'KLOUD' : 'Knowledge Base';
      this.$logger.info(`${enablePref}abling ${featureEnabledReadable} for programme`, { programmeId });
      return this.$http.put(
        `/api/curriculum/blueprints/programmes/${programmeId}/${enablePref}able-${featureEnabledURL}`,
      ).then(() => {
        this.$logger.info(`${enablePref}abled ${featureEnabledReadable} for programme`, { programmeId });
        this.$ktoast.success(`${featureEnabledReadable} ${enablePref}abled`);
        this.getProgrammeBlueprints();
      }).catch(err => {
        this.$logger.error(`Error ${enablePref}abling ${featureEnabledReadable} for programme`, { programmeId }, err);
        this.showError(err);
      });
    },

    toastFeatureEnableCallback(row, featureEnabled) {
      const progId = row.programmeId;
      const enabled = row[featureEnabled].enabled;
      this.enableFeatureInProgramme(progId, featureEnabled, enabled);
    },

    tableFeatureEnableCallback(row, featureEnabled) {
      this.$ktoast.confirmToast(
        this.getProgrammeFeatureEnableToastMessage(row, featureEnabled),
        'warning',
        () => {
          this.toastFeatureEnableCallback(row, featureEnabled);
        },
      );
    },
  },
};
</script>
