<template>
  <div class="row">
    <div class="meeting-form-container col-md-7 col-xs-12">
      <h3>Details</h3>
      <form class="meeting-details">
        <fieldset class="form-group form-entry col-md-8">
          <label for="meeting-title-input">Title
            <input required
              id="meeting-title-input"
              type="text"
              class="form-control"
              placeholder="Name title"
              v-model="meetingTitle">
            <small>Please keep the title generic and not programme specific</small>
          </label>
        </fieldset>
        <fieldset class="form-group form-entry col-md-12 meeting-description">
          <label for="meeting-description-input">Description
            <textarea id="meeting-description-input"
              class="form-control"
              placeholder="Enter description here..."
              rows="3"
              v-model="meetingDescription">
            </textarea>
          </label>
        </fieldset>
      </form>
      <word-tags v-model="meetingTags" textInputPlaceholder="Add a tag"></word-tags>
    </div>
    <div class="col-md-12">
      <button class="submit-button btn btn-success save-button"
        aria-label="Add event" title="Add event"
        :disabled="!validForm"
        @click="addEvent">Add Event</button>
      <br/>
    </div>
  </div>
</template>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
}

section h2 {
  margin-top: 0;
  margin-bottom: 25px;
}

.form-group small {
  font-style: italic;
}

.meeting-form-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.meeting-form-container form {
  margin-left: -15px;
}

.meeting-description label,
.meeting-details label {
  width: 100%;
}

.meeting-details {
  padding-top: 15px;
}
</style>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import WordTags from '../../../components/word-tags.vue';

export default {

  components: {
    'word-tags': WordTags,
  },

  mixins: [ErrorMixin],

  data() {
    return {
      meetingTitle: '',
      meetingDescription: '',
      meetingTags: [],
    };
  },

  computed: {
    validTitle() {
      return this.meetingTitle.trim().length > 0;
    },

    validForm() {
      return Boolean(this.validTitle);
    },
  },

  methods: {
    addEvent() {
      const eventData = {
        name: this.meetingTitle,
        description: this.meetingDescription,
        tags: this.meetingTags,
      };
      this.$logger.info('Adding Meeting', { eventData }, true);
      this.$http.post('/api/curriculum/admin/meeting', eventData).then(res => {
        this.$ktoast.success('New Event Added');
        this.$logger.info('Added Meeting to the database successfully', { event_id: res.data.meeting_id });
        this.$emit('meeting-added');
      }).catch(err => {
        this.$logger.error('Error writing meeting to database', { eventData }, err);
        this.showError(err);
      });
    },

    clearFields() {
      this.meetingTitle = '';
      this.meetingDescription = '';
      this.meetingTags = [];
    },
  },
};
</script>
