<template>
  <div class="release-asset-container">
    <div class="controls">
      <button class="btn btn-danger chapter-mode-btn" @click="$emit('close-edit')">
        Cancel
      </button>
      <button class="btn btn-success save-btn" @click="updateChapters">
        Save changes
      <intersection-observer observed-element="save-changes"
        @on-intersection-element="onElementInView"
      ></intersection-observer>
      </button>
    </div>
    <splitpanes class="custom-theme modal-theme">
      <pane class="asset-list-container" min-size="30" max-size="70" :size="45">
        <div class="chapter-management">
          <div class="controls custom">
            <h3 class="pane-title">Default Asset Chapters</h3>
          </div>
          <ol>
            <li v-for="(chapter, idx) in leftPaneChapters" :key="chapter.number" v-show="chapter.assets.length > 0">
              <chapter-item v-if="chapter.assets.length > 0"
                :index="idx"
                :title="chapter.title"
                :assets="chapter.assets"
                :editable="false"
                :asset-interaction-enabled="!isCustomChapter(chapter)"
                :is-chapter-selected="false"
                :disabled-assets="stagedAssets"
                @select-asset="(asset) => addAssetToChapter(asset)"
              >
              </chapter-item>
            </li>
          </ol>
          <span v-if="leftPaneAssets.length === 0" class="empty-message">All available assets have been allocated to custom chapters</span>
        </div>
      </pane>
      <pane class="release-pane" min-size="30" max-size="70" :size="55">
        <div class="chapter-management">
          <div class="controls custom">
            <h3 class="pane-title">Custom Chapters</h3>
            <button class="btn btn-outlined" @click="addChapter">
              Add Chapter
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>
          <draggable
            v-model="rightPaneChapters"
            draggable=".chapter-item"
            ghost-class="ghost"
            @change="reorderCustomChapters"
          >
            <chapter-item
              class="chapter-item"
              v-for="(chapter, idx) in rightPaneChapters"
              :key="chapter.number"
              :class="idx === currentlySelectedChapter ? 'selected' : ''"
              :index="idx"
              :title="chapter.title"
              :label="chapter.label"
              :assets="chapter.assets"
              :editable="true"
              :asset-interaction-enabled="true"
              :is-chapter-selected="idx === currentlySelectedChapter"
              :default-chapter-titles="defaultChapterTitles"
              @update-title-and-label="payload => updateChapterTitleAndLabel(idx, payload)"
              @select-asset="asset => removeAssetFromChapter(idx, asset)"
              @delete-chapter="deleteChapter(idx)"
              @select-chapter="selectChapter(idx)"
              @assets-reordered="(assets) => chapter.assets = assets">
            </chapter-item>
          </draggable>
        </div>
      </pane>
    </splitpanes>
    <button v-if="!isInViewElement" class="btn btn-success save-btn" @click="updateChapters">Save changes</button>
  </div>
</template>

<style scoped>
.release-pane {
  overflow: visible;
}

.controls {
  display: flex;
  justify-content: right;
  gap: 15px;
}

.controls.custom {
  flex: 1;
  align-items: start;
  justify-content: space-between;
}

.controls button,
button.save-btn {
  display: flex;
  padding: 8px 25px;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

button.save-btn {
  display: block;
  float: right;
  color: var(--kate-type-dark);
}

.empty-message {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.asset-list-container {
  padding: 15px;
}

.asset-list-container ol {
  padding: 0;
}

.chapter-management div.chapter {
  margin-top: 15px;
  cursor: pointer;
}

.chapter-management div.chapter.selected {
  box-shadow: var(--inner-shadow);
}

.chapter-management div.chapter:hover {
  background-color: var(--kate-panel-alt);
}

.chapter-management div.chapter.selected:hover {
  background-color: transparent;
}

.chapter-management + .save-btn {
  margin-bottom: 0;
  margin-top: 15px;
}

.release-asset-container {
  padding-bottom: 30px;
}

</style>

<style>
.chapter-management ol {
  list-style: none;
  padding-left: 15px;
}

.release-asset-container div.static li.list-items .item.selected {
  transform: translateX(0);
  background-color: var(--kate-primary);
}

i.fa-exclamation-triangle.warning {
  color: var(--kate-warning-ligh);
}

</style>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import { Splitpanes, Pane } from 'splitpanes';
import ErrorMixin from '../../../../../mixins/error-mixins';
import ModuleTypeMixin from '../../../../../mixins/module-type-mixin';
import ChapterItem from './chapter-item.vue';
import IntersectionObserver from '../../../../../components/k-intersection-observer.vue';
import { sortObjectArray } from '../../../../../modules/sort-by-object-property';
import flatten from '../../../../../modules/flatten';
import copyObject from '../../../../../modules/copy-object';
import { ASSET_TYPES, MODULE_TYPES } from '../../../../../constants';

export default {
  components: {
    Splitpanes,
    draggable: VueDraggableNext,
    Pane,
    ChapterItem,
    IntersectionObserver,
  },

  mixins: [ErrorMixin, ModuleTypeMixin],

  props: {
    moduleId: Number,
    moduleType: {
      type: String,
      default: 'module',
      validator: value => Object.keys(MODULE_TYPES).includes(value),
    },
    chapters: Array,
  },

  data() {
    return {
      leftPaneChapters: [],
      rightPaneChapters: [],
      currentlySelectedChapter: undefined,
      isInViewElement: false,
    };
  },

  beforeMount() {
    // Deep clone to prevent changes from mutating chapters
    this.leftPaneChapters = copyObject(this.chapters.filter(c => this.defaultChapterTitles.indexOf(c.title) !== -1));
    this.rightPaneChapters = copyObject(this.chapters.filter(c => this.defaultChapterTitles.indexOf(c.title) === -1));
    // Initially order the chapter assets by number
    for (let i = 0; i < this.leftPaneChapters.length; i++) {
      const ch = this.leftPaneChapters[i];
      ch.assets = sortObjectArray(ch.assets, 'number');
    }
    for (let i = 0; i < this.rightPaneChapters.length; i++) {
      const ch = this.rightPaneChapters[i];
      ch.assets = sortObjectArray(ch.assets, 'number');
    }
  },

  watch: {
    chapters() {
      // Deep clone to prevent changes from mutating chapters
      this.leftPaneChapters = copyObject(this.chapters.filter(c => this.defaultChapterTitles.indexOf(c.title) !== -1));
      this.rightPaneChapters = copyObject(this.chapters.filter(c => this.defaultChapterTitles.indexOf(c.title) === -1));
    },
  },

  computed: {
    chaptersEndpoint() {
      return `${this.moduleTypeMeta.moduleApiRoot}/${this.moduleId}/chapters`;
    },
    defaultChapterTitles() {
      const titles = [];
      Object.keys(ASSET_TYPES).forEach(assetType => {
        titles.push(ASSET_TYPES[assetType].defaultChapterTitle);
      });
      return titles;
    },
    updatePayload() {
      if (this.moduleType === 'module_blueprint') {
        const customChapterAssets = flatten(
          this.rightPaneChapters.map((ch, chIdx) => ch.assets.map((a, idx) => ({
            asset_type: a.asset_type,
            is_mandatory: a.is_mandatory,
            asset_id: a[`${a.asset_type}_id`],
            chapter: {
              title: ch.title,
              label: ch.label,
              number: chIdx + 1,
            },
            number: idx + 1,
            id: a.id,
          }))),
        );
        const defaultChapterAssets = flatten(
          this.leftPaneChapters.map(
            // Avoid duplicating staged assets
            ch => ch.assets
              .filter(a => !this.isStagedAsset(a))
              .map((a, idx) => ({
                asset_type: a.asset_type,
                asset_id: a[`${a.asset_type}_id`],
                chapter: null,
                number: idx + 1,
                id: a.id,
              })),
          ),
        );
        return [...customChapterAssets, ...defaultChapterAssets];
      }
      return {
        chapters: this.rightPaneChapters.map((ch, idx) => ({
          title: ch.title,
          label: ch.label,
          number: idx + 1,
          assets: ch.assets.map((asset, index) => ({
            asset_id: asset.id,
            asset_type: asset.asset_type,
            number: index + 1,
          })),
        })),
      };
    },
    stagedAssets() {
      return this.rightPaneChapters.reduce((output, chapter) => output.concat(chapter.assets.map(a => ({ id: a.id, asset_type: a.asset_type }))), []);
    },
    leftPaneAssets() {
      return this.leftPaneChapters.reduce((output, chapter) => output.concat(chapter.assets.map(a => ({ id: a.id, asset_type: a.asset_type }))), []);
    },
  },

  methods: {
    addChapter() {
      this.rightPaneChapters.push({
        title: 'Chapter Title',
        assets: [],
        selected: false,
      });
    },
    selectChapter(idx) {
      if (this.currentlySelectedChapter === idx) {
        this.currentlySelectedChapter = undefined;
      } else {
        this.currentlySelectedChapter = idx;
      }
    },
    isCustomChapter(chapter) {
      return this.defaultChapterTitles.indexOf(chapter.title) === -1;
    },
    isStagedAsset(asset) {
      return Boolean(this.stagedAssets.find(sa => sa.id === asset.id && sa.asset_type === asset.asset_type));
    },
    deleteChapter(idx) {
      if (idx < this.rightPaneChapters.length) {
        this.rightPaneChapters[idx].assets.map(asset => this.removeAssetFromChapter(idx, asset));
        this.rightPaneChapters.splice(idx, 1);
        this.reorderCustomChapters();
      }
    },
    updateChapterTitleAndLabel(idx, payload) {
      if (idx < this.rightPaneChapters.length) {
        this.rightPaneChapters[idx].title = payload.title;
        this.rightPaneChapters[idx].label = payload.label;
      }
    },
    reorderCustomChapters() {
      this.rightPaneChapters = this.rightPaneChapters.map((x, idx) => ({
        ...this.rightPaneChapters[idx],
        number: idx + 1,
      }));
    },
    addAssetToChapter(asset) {
      if (this.currentlySelectedChapter > -1) {
        this.rightPaneChapters[this.currentlySelectedChapter].assets.push(asset);
      } else {
        this.$ktoast.warning('Please select a Chapter');
      }
    },
    removeAssetFromChapter(chapterIndex, asset) {
      this.rightPaneChapters[chapterIndex].assets = this.rightPaneChapters[chapterIndex].assets.filter(a => !(a.id === asset.id && a.asset_type === asset.asset_type));
      if (this.isCustomChapter(this.rightPaneChapters[chapterIndex])) {
        // Find the default chapter for that asset type and add the removed asset
        const defaultChapter = this.leftPaneChapters.find(ch => ch.title === ASSET_TYPES[asset.asset_type].defaultChapterTitle);
        // Make sure it won't be a duplicate
        if (!defaultChapter.assets.find(a => a.id === asset.id && a.asset_type === asset.asset_type)) {
          defaultChapter.assets.push(asset);
        }
      }
    },
    updateChapters() {
      const logContext = { };
      logContext[this.moduleTypeMeta.moduleIdField] = this.moduleIdField;
      this.$logger.info(`Updating ${this.moduleTypeMeta.name} chapters`, logContext, true);
      this.$Loading.minimal();
      this.$http.put(this.chaptersEndpoint, this.updatePayload).then(() => {
        this.$logger.info(`Successfully updated ${this.moduleTypeMeta.name} chapters`, logContext, true);
        this.$ktoast.success('Chapters updated');
        this.$emit('close-edit');
        this.$emit('chapters-updated');
      }).catch(err => {
        this.$logger.error(`Error updating chapters for ${this.moduleTypeMeta.name}`, logContext);
        this.showError(err);
      }).then(() => {
        this.$Loading.finish();
      });
    },
    onElementInView(value) {
      this.isInViewElement = value;
    },
  },
};
</script>
