<template>
  <div>
    <div class="achievement-form-container col-md-7 col-xs-12">
      <h3>Details</h3>
      <form class="achievement-details">
        <fieldset class="form-group form-entry col-md-8">
          <label>Name
            <input required
              id="achievement-title-input"
              type="text"
              class="form-control"
              placeholder="Type title"
              v-model="achievementName"
              :disabled="isUploading">
            <small>Please keep the title generic</small>
          </label>
        </fieldset>
        <fieldset class="form-group form-entry col-md-12 achievement-description">
          <label>Description
            <textarea id="achievement-description-input"
              class="form-control"
              placeholder="Enter description here..."
              rows="3"
              v-model="achievementDescription"
              :disabled="isUploading">
            </textarea>
          </label>
        </fieldset>
      </form>
    </div>
    <div class="col-md-5 col-xs-12">
      <h3>Upload</h3>
      <dropfield @file="filesChange"
        ref="achievementUploadField"
        :customMessage="dropfieldMessage"
        :immediatePost="false"
        :disableDropfield="isUploading"
        accept="image/jpeg,image/png"
        url=""
        :enablePreviews="true"
        id="k-add-achievement-dropfield">
      </dropfield>
    </div>
    <div class="col-md-12 col-xs-12">
      <button class="submit-button btn btn-success save-button"
        :disabled="!validForm"
        aria-label="Submit" title="Submit"
        @click="uploadAchievement">
        <i :class="isUploading ? 'fa fa-spinner fa-spin' : 'fas fa-upload'"></i>
        {{ isUploading ? 'Uploading...' : `Upload ${achievementType}` }}
      </button>
      <br/>
      <small v-if="isUploading">
        <i>This may take a few minutes. Please do not navigate away from this page.</i>
      </small>
    </div>
  </div>
</template>

<style scoped>
.form-group small {
  font-style: italic;
}

.achievement-form-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.achievement-form-container form {
  margin-left: -15px;
}

.achievement-description label,
.achievement-details label {
  width: 100%;
}

#k-add-achievement-dropfield,
.achievement-details {
  padding-top: 15px;
}

.achievement-preview-modal figure {
  text-align: center;
}

.preview-achievement {
  max-height: 70vh;
  width: auto;
}
</style>

<script>
import ErrorMixin from '../../mixins/error-mixins';
import TimeMixin from '../../mixins/time-mixins';
import Dropfield from '../../components/dropfield.vue';

export default {
  components: {
    Dropfield,
  },

  mixins: [ErrorMixin, TimeMixin],

  props: {
    achievementType: String,
    modelValue: { // Not sure if this is required
      type: Object,
    },
  },

  data() {
    return {
      achievementName: '',
      achievementDescription: '',
      file: undefined,
      dropfieldMessage: `<i class="fas fa-cloud-upload-alt"></i>
      Click or drag image file here to upload.`,
      isUploading: false,
      resources: [],
    };
  },

  watch: {
    isUploading() {
      if (this.isUploading) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },

  computed: {
    validName() {
      return this.achievementName.trim().length > 0;
    },

    validForm() {
      return Boolean(this.validName && this.file);
    },

    achievementEndpoint() {
      return `/api/curriculum/${this.achievementType}`;
    },
  },

  methods: {
    uploadAchievement() {
      this.isUploading = true;
      let achievementMeta = {
        name: this.achievementName,
        description: this.achievementDescription,
      };
      this.$logger.info(`Adding ${this.achievementType} to database`, { achievementMeta }, true);
      this.$http.post(this.achievementEndpoint, achievementMeta).then(res => {
        const achievementId = this.achievementType === 'badge' ? res.data.badge_id : res.data.certificate_id;
        this.$logger.info(`Added ${this.achievementType} to the database successfully`, { achievement_id: achievementId });
        const payload = {
          file_name: this.file[0].name,
          file_size: this.file[0].size,
          content_type: this.file[0].type,
        };
        // POST request to initiate resumable upload and return upload URL
        this.$logger.info('Getting signed resumable upload URL');
        this.$http.post(`${this.achievementEndpoint}/${achievementId}/upload-url`, payload)
          .then(response => {
            this.$logger.info('Got signed resumable upload URL', undefined, false);
            const url = response.data.upload_url;
            this.$logger.info('Attempting file upload', undefined, true);
            // PUT request to upload using signed URL
            this.$http.put(url, this.file[0]).then(() => {
              this.$logger.info('File uploaded successfully');
              achievementMeta = {
                file_name: this.file[0].name,
                name: this.achievementName,
                description: this.achievementDescription,
                icon: this.file[0].name,
              };
              this.$logger.info(`Updating ${this.achievementType} database entry with resource link`, { achievementMeta, file: this.file.name }, false);
              this.$http.put(`${this.achievementEndpoint}/${achievementId}`, achievementMeta).then(() => {
                this.$logger.info(`Added ${this.achievementType} to the database succesfully`, { achievementId });
                this.$ktoast.success(`${this.achievementType} uploaded`);
                this.clearFields();
                this.$emit('achievement-created');
                this.isUploading = false;
              }).catch(err => {
                this.$logger.error(`Error updating ${this.achievementType} resource link`, { achievementMeta, file: this.file.name }, err);
                this.showError(err);
                this.isUploading = false;
              });
            }).catch(err => {
              this.$logger.error(`Error uploading ${this.achievementType} with signed resumable upload URL`, undefined, err);
              this.showError(err);
              this.isUploading = false;
            });
          }).catch(err => {
            this.$logger.error('Error getting signed resumable upload URL', undefined, err);
            this.showError(err);
            this.isUploading = false;
          });
      }).catch(err => {
        this.$logger.error(`Error writing ${this.achievementType} meta to database`, { achievementMeta }, err);
        this.showError(err);
        this.isUploading = false;
      });
    },

    filesChange(file) {
      this.$logger.info('Staged file for upload', { file: file.name });
      this.file = file;
    },

    clearFields() {
      this.$refs.achievementUploadField.reset();
      this.achievementName = '';
      this.achievementDescription = '';
    },
  },
};
</script>
