<template>
  <div>
    <manage-chapters v-if="editChapterMode"
      :moduleId="moduleId"
      :moduleType="moduleType"
      :chapters="chapters"
      @chaptersUpdated="$emit('chaptersUpdated', $event)"
      @closeEdit="editChapterMode = !editChapterMode"
    ></manage-chapters>
    <section v-else>
      <button class="btn btn-primary chapter-mode-btn" @click="editChapterMode = !editChapterMode">
        Manage Chapters
      </button>
      <chapter-overview v-for="chapter in chapters"
        :key="chapter.number"
        :chapter="chapter">
      </chapter-overview>
    </section>
  </div>
</template>

<style scoped>
button.chapter-mode-btn {
  float: right;
  margin-bottom: 15px;
}
</style>

<script>
import ChapterOverview from './chapters/chapter-overview.vue';
import ManageChapters from './chapters/manage-chapters.vue';
import { MODULE_TYPES } from '../../../../constants';

export default {
  components: {
    ChapterOverview,
    ManageChapters,
  },

  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    moduleType: {
      type: String,
      default: 'module',
      validator: value => Object.keys(MODULE_TYPES).includes(value),
    },
    chapters: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      editChapterMode: false,
    };
  },
};
</script>
