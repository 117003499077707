<template>
  <div class="note-container">
    <div class="note-header">
      <div class="note-details">
        <div class="author-info">
          <h3>{{ note.author_name }}</h3>
          <i>{{ parseTimestamp(note.date_created) }}</i>
          <span v-if="note.date_updated" class="update-timestamp"><small>(updated: {{ parseTimestamp(note.date_updated) }})</small></span>
        </div>
        <div v-if="!note.share_with_employer" class="share-tag">
          <k-tooltip text="This note has not been shared with the employer" position="right">
            <div class="tags">
              <span class="danger">Not shared</span>
            </div>
          </k-tooltip>
        </div>
      </div>
      <div v-if="hasInteractionPermission" class="note-actions">
        <button class="btn btn-edit" @click="$emit('openEditModal')" @edited="$emit('edited')">
          <i class="fa-duotone fa-pencil"></i>
        </button>
        <div class="deleting-action">
          <button v-if="deletingNote" class="btn btn-trash loading-icon" disabled>
            <i class="fa-duotone fa-spinner fa-spin"></i>
          </button>
          <button v-else class="btn btn-trash" @click="openDeleteModal">
            <i class="fa-duotone fa-trash-can"></i>
          </button>
        </div>
      </div>

    </div>
    <div class="note-content">
      <p v-html="highlightedContent"></p>
    </div>
    <k-modal :show="showDeleteModal" @close="closeDeleteModal" class="note-delete-modal">
      <template #header>
        <h3>Confirm deleting note</h3>
      </template>
      <template #body>
        <div class="note-modal-body">
          <p>Are you sure you want to delete this note?</p>
          <p><b>This action cannot be undone.</b></p>
        </div>
      </template>
      <template #footer>
        <button class="modal-return-button btn btn-default cancel-btn" @click="closeDeleteModal">Cancel</button>
        <button v-if="deletingNote" class="btn btn-danger modal-delete-button" disabled>
          Deleting Note <i class="fas fa-spinner fa-spin"></i>
        </button>
        <button v-else class="btn btn-danger modal-delete-button" @click="deleteNote()">
          Delete Note
        </button>
      </template>
    </k-modal>
  </div>
</template>
<style>
.notes-overview .notes-list .note-container:nth-child(odd) {
  background-color: var(--kate-note);
}

.notes-overview .notes-list .note-container:nth-child(odd)::before {
  border-right-color: var(--kate-note);
}

.notes-overview .confirm-discard-modal .modal-container,
.notes-overview .note-container .modal-container {
  min-width: unset;
}

.notes-overview .confirm-discard-modal .modal-container {
  border-left: 12px solid var(--kate-warning);
}

.notes-overview .note-container .modal-container {
  border-left: 12px solid var(--kate-danger-alt);
}

.note-container .modal-container .note-modal-body p b {
  color: var(--kate-type-light);
}

.note-container .note-content pre {
  white-space: normal;
  word-break: break-word;
}
</style>
<style scoped>
.note-container {
  background: var(--kate-note-alt);
  padding: 1.2em;
  margin: 0 15px 15px;
  border-radius: 4px;
  position: relative;
  box-shadow: var(--box-shadow);
}

.note-container::before {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: var(--kate-note-alt);
  border-width: 10px;
  top: 10px;
}

.note-details .author-info,
.note-actions,
.note-details,
.note-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.note-header {
  margin-bottom: 15px;
}

.note-actions {
  justify-content: end;
  align-items: baseline;
}

.note-actions button i {
  text-shadow: 2px 2px 0 var(--kate-type-dark);
}

.note-header .btn-trash i {
  color: var(--kate-danger-alt);
}

.note-header .btn-trash:hover i {
  color: var(--kate-danger);
}

.note-details,
.note-details .author-info {
  row-gap: 0;
}

.note-details .author-info {
  justify-content: flex-start;
}

.share-tag {
  width: fit-content;
  flex: 1 1 100%;
}
</style>

<script>
import ErrorMixin from '../../../mixins/error-mixins';
import TimeMixin from '../../../mixins/time-mixins';
import KTooltip from '../../../components/k-tooltip.vue';
import KModal from '../../../components/k-modal.vue';

export default {
  components: {
    KModal,
    KTooltip,
  },

  props: {
    programmeMemberId: {
      type: Number,
      required: true,
    },
    note: {
      type: Object,
      required: true,
    },
    filterText: {
      type: String,
      default: '',
    },
    hasInteractionPermission: Boolean,
  },
  mixins: [ErrorMixin, TimeMixin],

  data() {
    return {
      deletingNote: false,
      showDeleteModal: false,
    };
  },

  computed: {
    highlightedContent() {
      if (!this.filterText) {
        return this.$kpurify.sanitise(this.note.note);
      }
      return this.$kpurify.sanitise(this.note.note.replace(new RegExp(this.filterText, 'gi'), y => `<span class="highlighted-text">${y}</span>`));
    },
  },

  methods: {
    deleteNote() {
      this.deletingNote = true;
      this.$logger.info('Deleting note', undefined, true);
      this.$http.delete(`/api/profile/programme-member/${this.programmeMemberId}/notes/${this.note.note_id}`).then(() => {
        this.$ktoast.success('Note has been deleted');
        this.$logger.info('Deleted note', { noteId: this.note.note_id });
        this.$emit('update');
        this.closeDeleteModal();
      }).catch(err => {
        this.$logger.autowarn('Error deleting note', { progId: this.programmeId }, err);
        this.showError(err);
      }).then(() => {
        this.deletingNote = false;
      });
    },

    openDeleteModal() {
      this.showDeleteModal = true;
    },

    closeDeleteModal() {
      this.showDeleteModal = false;
    },
  },
};
</script>
