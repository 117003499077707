<template>
  <k-modal
    id="update-programme-modal"
    v-if="programme"
    :show="show"
    @close="$emit('close')">
    <template #header>
      <h3><i class="fas fa-edit"></i>Edit Programme - {{programme.id}}</h3>
    </template>
    <template #body>
      <div class="col-md-12">
        <div class="col-md-8">
          <label for="programme-title-input">Name</label>
          <input id="programme-title-input" v-model="newProgrammeName" class="form-control">
        </div>
        <div class="col-md-4">
          <k-date-picker label="Planned End Date" v-model="newProgrammePlannedEndDate" inputId="end-date-input"></k-date-picker>
        </div>
        <div class="col-md-12 programme-description">
          <label for="editTextEditor">Description</label>
          <k-text-editor id="editTextEditor"
            v-model="newProgrammeDescription"
            :customToolbar="[['bold', 'italic', 'underline'], ['link'], [{ list: 'ordered' }, { list: 'bullet' }]]"
            :placeholder="'Briefly describe the programme...'"
          ></k-text-editor>
        </div>
      </div>
      <div class="col-md-12">
        <div class="col-md-6">
          <k-word-tags label="Groups" v-model="newProgrammeGroups" :textInputPlaceholder="'Type groups here'"></k-word-tags>
          <ul class="k-word-tags-list">
            <li class="k-word-tags-item badge" v-for="group in existingGroups" :key="group">
              {{group}}
            </li>
          </ul>
          <p class="info">
            <i class="fas fa-exclamation-triangle icon-warning"></i> You will not be able to change these once they are set
          </p>
        </div>
        <div class="col-md-6" v-if="products.length">
          <span>Products</span>
          <k-dropdown
            v-model="newProgrammeProductID"
            placeholder="Select a product"
            :options="products"
            :clear-option=true
          ></k-dropdown>
        </div>
      </div>
      <div class="col-md-12">
        <div class="col-md-6 programme-checkbox">
          <span>Include in reporting:</span>
          <label class="k-custom-checkbox" for="include-in-report-checkbox">
            <input type="checkbox" class="hidden-checkbox" v-model="newProgrammeIncludeInReport" id="include-in-report-checkbox">
            <span class="k-custom-checkbox-box"></span>
            <span class="k-custom-checkbox-text">Add learner data to the Tableau dashboards</span>
          </label>
        </div>
      </div>
    </template>
    <template #footer>
      <button class="btn btn-outlined" @click="$emit('close')">Cancel</button>
      <button @click="updateProgramme" class="btn btn-primary send-btn">Update</button>
    </template>
  </k-modal>
</template>

<style>
#update-programme-modal .modal-body {
  min-height: 370px;
}

#update-programme-modal .modal-container {
  max-width: 80vw;
}
</style>

<style scoped>
textarea {
  max-width: 100%;
  resize: none;
}

.programme-description {
  margin: 15px 0;
}

.programme-checkbox input[type="checkbox"] {
  margin: 0;
}

.k-word-tags-list {
  list-style: none;
  margin-top: 10px;
  padding-left: 0;
}

.k-word-tags-list > li {
  color: white !important;
}
</style>

<script>
import ErrorMixin from '../../mixins/error-mixins';
import TimeMixin from '../../mixins/time-mixins';
import KModal from '../../components/k-modal.vue';
import KDatePicker from '../../components/k-date-picker.vue';
import KDropdown from '../../components/k-dropdown.vue';
import KTextEditor from '../../components/k-text-editor.vue';
import WordTags from '../components/word-tags.vue';

export default {
  components: {
    'k-word-tags': WordTags,
    'k-modal': KModal,
    'k-date-picker': KDatePicker,
    'k-dropdown': KDropdown,
    'k-text-editor': KTextEditor,
  },
  mixins: [ErrorMixin, TimeMixin],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    programme: {
      type: Object,
    },
  },
  data() {
    return {
      newProgrammeName: undefined,
      newProgrammeDescription: undefined,
      newProgrammeProductID: undefined,
      newProgrammeIncludeInReport: undefined,
      newProgrammePlannedEndDate: undefined,
      newProgrammeGroups: [],
      updateInProgress: false,
    };
  },
  watch: {
    programme() {
      if (this.programme) {
        this.newProgrammeName = this.programme.name;
        this.newProgrammeDescription = this.programme.description || '';
        this.newProgrammeIncludeInReport = this.programme.include_in_reporting;
        this.newProgrammeProductID = this.programme.product_id;
        this.newProgrammePlannedEndDate = this.programme.planned_end_date;
        this.newProgrammeGroups = [];
      } else {
        this.newProgrammeName = undefined;
        this.newProgrammeDescription = undefined;
        this.newProgrammeIncludeInReport = undefined;
        this.newProgrammeProductID = undefined;
        this.newProgrammePlannedEndDate = undefined;
        this.newProgrammeGroups = [];
      }
    },
    updateInProgress() {
      if (this.updateInProgress) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },
  computed: {
    show() {
      return Boolean(this.programme);
    },
    existingGroups() {
      if (!this.programme.programme_groups) {
        return [];
      }
      return this.programme.programme_groups.map(group => group.name);
    },
  },

  methods: {
    updateProgramme() {
      const updatePayload = {
        name: this.newProgrammeName.trim(),
        description: this.newProgrammeDescription,
        groups: this.newProgrammeGroups,
        include_in_reporting: this.newProgrammeIncludeInReport,
        product_id: this.newProgrammeProductID ? this.newProgrammeProductID : null,
        planned_end_date: this.newProgrammePlannedEndDate ? this.formatDate(this.newProgrammePlannedEndDate) : undefined,
      };
      this.$logger.info('Updating programme', { programmeId: this.programme.id, updatePayload }, true);
      this.updateInProgress = true;
      this.$http.put(`/api/curriculum/programmes/${this.programme.id}`, updatePayload).then(() => {
        this.$logger.info('Successfully updated programme', { programmeId: this.programme.id, updatePayload });
        this.$ktoast.success('Programme updated');
        this.$emit('programme-updated');
        this.$emit('close');
      }).catch(err => {
        this.showError(err);
        this.$logger.error('Error updating programme', { programmeId: this.programme.id, updatePayload }, err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
  },
};
</script>
