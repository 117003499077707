<template>
  <div class="panel panel-default current-programme-blueprint">
    <div class="panel-body">
      <p>To make changes to a {{blueprintType}} blueprint, click on the blueprint <i class="highlight">name</i> in the table</p>
      <k-table
        :headers="kableHeaders"
        :rows="kableRows"
        :hideable="true"
        :panel="false"
        :max="20"
        @clicked="tableCallback">
      </k-table>
    </div>
  </div>
</template>

<script>
import KTable from '../../../components/k-table.vue';

export default {
  components: {
    KTable,
  },

  props: {
    kableRows: {
      type: Array,
      required: true,
    },
    kableHeaders: {
      type: Object,
      required: true,
    },
    tableCallback: {
      type: Function,
      required: true,
    },
    blueprintType: {
      type: String,
      required: true,
    },
  },
};
</script>
