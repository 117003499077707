<template>
  <div v-if="ready">
    <add-programme-members
      :programme="programme"
      @update="getProgrammeMembers"
    ></add-programme-members>
    <current-programme-members
      :programme="programme"
      :members="programmeMembers"
      @update="getProgrammeMembers"
    ></current-programme-members>
  </div>
</template>

<script>
import AddProgrammeMembers from './student_management/add-programme-members.vue';
import CurrentProgrammeMembers from './student_management/current-programme-members.vue';
import PageReadyMixin from '../../mixins/page-ready-mixin';
import ErrorMixin from '../../mixins/error-mixins';

export default {
  components: {
    AddProgrammeMembers,
    CurrentProgrammeMembers,
  },

  mixins: [PageReadyMixin, ErrorMixin],

  props: {
    programme: {
      type: Object,
    },
    programmeReady: {
      type: Boolean,
    },
  },

  data() {
    return {
      programmeMembers: [],
      programmeMembersReady: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.$emit('refresh-programme');
  },

  mounted() {
    if (this.programme) {
      this.registerCrumbs();
    }
    this.getProgrammeMembers();
  },

  computed: {
    programmeId() {
      return Number(this.$route.params.programmeId);
    },
    ready() {
      return this.programmeMembersReady && this.programmeReady;
    },
  },

  methods: {
    getProgrammeMembers() {
      this.programmeMembers = [];
      this.programmeMembersReady = false;
      this.$logger.info('Fetching programme members', { programmeId: this.programmeId }, true);
      this.$http.get(`/api/curriculum/programmes/${this.programmeId}/members`).then(res => {
        this.$logger.info('Successfully fetched programme members', { programmeId: this.programmeId });
        this.programmeMembers = res.data.members;
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          this.$logger.warn('Programme members not found', { programmeId: this.programmeId }, err);
        } else {
          this.$logger.error('Error fetching programme members', { programmeId: this.programmeId }, err);
          this.showError(err);
        }
      }).then(() => {
        this.programmeMembersReady = true;
      });
    },
    registerCrumbs() {
      this.$crumbs.register([
        {
          text: 'Manage Programmes',
          path: {
            name: 'programmes_overview',
          },
        },
        {
          text: this.programme.name || this.programmeId,
          active: true,
        },
      ]);
    },
    pageReadyCallback() {
      this.registerCrumbs();
    },
  },
};
</script>
