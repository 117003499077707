<template>
  <div class="row">
    <div class="calendar-event-form-container col-md-7 col-xs-12">
      <h3>Details</h3>
      <form class="calendar-event-details">
        <fieldset class="form-group form-entry col-md-8">
          <label>Title
            <input required
              id="calendar-event-title-input"
              type="text"
              class="form-control"
              placeholder="Name title"
              v-model="calendarEventTitle">
            <small>Please keep the title generic and not programme specific</small>
          </label>
        </fieldset>
        <fieldset class="form-group form-entry col-md-8">
          <!-- // jscpd:ignore-start -->
          <label for="live">
            <input type="checkbox" id="live" value="live" v-model="completionTypeLive"> Live
          </label>
          <label for="recorded">
            <input type="checkbox" id="recorded" value="recorded" v-model="completionTypeRecorded"> Recorded
          </label>
          <!-- // jscpd:ignore-end -->
        </fieldset>
        <fieldset class="form-group form-entry col-md-12 calendar-event-description">
          <label>Description
            <textarea id="calendar-event-description-input"
              class="form-control"
              placeholder="Enter description here..."
              rows="3"
              v-model="calendarEventDescription">
            </textarea>
          </label>
        </fieldset>
      </form>
      <word-tags v-model="calendarEventTags" textInputPlaceholder="Add a tag"></word-tags>
    </div>
    <div class="col-md-12">
      <button class="submit-button btn btn-success save-button"
        aria-label="Add event" title="Add event"
        :disabled="!validForm"
        @click="addEvent">Add Event</button>
      <br/>
    </div>
  </div>
</template>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
}

section h2 {
  margin-top: 0;
  margin-bottom: 25px;
}

.form-group small {
  font-style: italic;
}

.calendar-event-form-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.calendar-event-form-container form {
  margin-left: -15px;
}

.calendar-event-description label,
.calendar-event-details label {
  width: 100%;
}

.calendar-event-details {
  padding-top: 15px;
}
</style>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import WordTags from '../../../components/word-tags.vue';

export default {

  components: {
    'word-tags': WordTags,
  },

  mixins: [ErrorMixin],

  data() {
    return {
      calendarEventTitle: '',
      calendarEventDescription: '',
      calendarEventTags: [],
      completionTypeLive: false,
      completionTypeRecorded: false,
    };
  },

  computed: {
    validTitle() {
      return this.calendarEventTitle.trim().length > 0;
    },

    validForm() {
      return Boolean(this.validTitle && (this.completionTypeLive || this.completionTypeRecorded));
    },
  },

  methods: {
    completionTypes() {
      const cts = [];
      if (this.completionTypeRecorded) {
        cts.push('recorded');
      }
      if (this.completionTypeLive) {
        cts.push('live');
      }
      return cts;
    },

    addEvent() {
      const eventData = {
        name: this.calendarEventTitle,
        description: this.calendarEventDescription,
        tags: this.calendarEventTags,
        completion_types: this.completionTypes(),
      };
      this.$logger.info('Adding Calendar Event', { eventData }, true);
      this.$http.post('/api/curriculum/admin/calendar_event', eventData).then(res => {
        this.$ktoast.success('New Event Added');
        this.$logger.info('Added Calendar Event to the database successfully', { event_id: res.data.calendar_event_id });
        this.$emit('calendar-event-added');
      }).catch(err => {
        this.$logger.error('Error writing calendar event to database', { eventData }, err);
        this.showError(err);
      });
    },

    clearFields() {
      this.calendarEventTitle = '';
      this.calendarEventDescription = '';
      this.calendarEventTags = [];
      this.completionTypeLive = false;
      this.completionTypeRecorded = false;
    },
  },
};
</script>
