<template>
  <div id="quiz-creation">
    <div class="quiz-details-form">
      <fieldset class="detail-form form-entry form-required">
        <label for="quiz-name-input">Name</label>
        <input id="quiz-name-input" class="form-control form-control" placeholder="Name" v-model="name">
      </fieldset>
      <fieldset class="detail-form form-entry">
        <word-tags v-model="tags" textInputPlaceholder="Add a tag"></word-tags>
      </fieldset>
      <fieldset class="detail-form form-entry form-required description">
        <label :for="`text-${$.uid}`">Description</label>
        <k-text-editor
          :id="`text-${$.uid}`"
          v-model="description"
          placeholder="Briefly describe the quiz">
        </k-text-editor>
      </fieldset>
    </div>
    <quiz-question-list v-model="questions"></quiz-question-list>
    <div class="next-button">
      <button class="btn btn-success" @click="handleSubmit"
          :disabled="!validQuiz || creationInProgress">
          <i class="fa fa-spinner fa-spin" v-if="creationInProgress"></i>
          {{ editMode ? 'Update' : 'Create' }} Quiz
      </button>
    </div>
  </div>
</template>

<style>
textarea {
  width: 100%;
  resize: vertical;
  min-height: 30px;
}
</style>

<style scoped>
#quiz-creation {
  margin-top: 25px;
}

h2 > i.fas.fa-angle-down,
h2 > i.fas.fa-angle-up {
  float: right;
}

.quiz-details-form {
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
}

.detail-form {
  min-width: 15em;
  flex: 1 1 40%;
}

.detail-form textarea {
  padding: 10px;
  width: 50vw;
  margin: 15px 0;
  max-height: 250px;
  min-height: 45px;
}

.detail-form label {
  display: block;
}

.next-button {
  text-align: right;
  flex: 1 1 100%;
}

@media only screen and (max-width: 920px) {
  .detail-form textarea {
    width: 100%;
  }
}
</style>

<script>
import ErrorMixin from '../../../../../mixins/error-mixins';
import TimeMixin from '../../../../../mixins/time-mixins';
import QuizQuestionList from './quiz-question-list.vue';
import WordTags from '../../../../components/word-tags.vue';
import KTextEditor from '../../../../../components/k-text-editor.vue';

export default {
  components: {
    QuizQuestionList,
    WordTags,
    KTextEditor,
  },

  mixins: [ErrorMixin, TimeMixin],

  props: {
    quiz: {
      type: Object,
      required: false,
      default: undefined,
    },
  },

  mounted() {
    // If a quiz is provided establish it as the initial values in the component data
    if (this.quiz) {
      this.name = this.quiz.name;
      this.description = this.quiz.description;
      this.questions = this.quiz.questions;
      this.tags = this.quiz.tags || [];
    }
  },

  beforeUnmount() {
    // Component unmounted so all staged files will be removed
    this.$emit('files-staged', false);
  },

  data() {
    return {
      name: '',
      description: '',
      tags: [],
      questions: [],
      creationInProgress: false,
    };
  },

  watch: {
    name(name) {
      if (this.quiz) {
        this.emitChanges({ name });
      }
    },
    description(description) {
      if (this.quiz) {
        this.emitChanges({ description });
      }
    },
    questions(questions) {
      if (this.quiz) {
        this.emitChanges({ questions });
      }
    },
    tags(tags) {
      if (this.quiz) {
        this.emitChanges({ tags });
      }
    },
    hasStagedFiles() {
      this.$emit('files-staged', this.hasStagedFiles);
    },
  },

  computed: {
    editMode() {
      return Boolean(this.quiz && this.quiz.id);
    },
    validQuiz() {
      return Boolean(
        this.name
        && this.description
        && this.questions.length > 0
        && this.questions.every(this.validateQuestion)
        && (this.tags.every(tag => tag.length >= 2) || this.tags.length === 0),
      );
    },
    hasStagedFiles() {
      if (!this.questions) {
        return false;
      }
      return Boolean(
        this.questions.map(q => q.stagedFiles && q.stagedFiles.length > 0).filter(x => x).length,
      );
    },
  },

  methods: {
    emitChanges(updates) {
      this.$emit('update:quiz', {
        ...this.quiz,
        ...updates,
      });
    },
    validateQuestion(question) {
      // Every question must have some text
      if (!question.question || question.question.length < 1) {
        return false;
      }
      // Every question must have at least one answer
      if (!question.answers || question.answers.length < 1) {
        return false;
      }
      // Every question must have at least one correct answer
      if (question.answers.filter(x => x.is_correct).length < 1) {
        return false;
      }
      // Every answer must have some answer text
      for (let i = 0; i < question.answers.length; i++) {
        const ans = question.answers[i];
        if (ans.answer.length < 1) {
          return false;
        }
      }
      return true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    formatResourcesForUpload(question) {
      const existingResources = [];
      if (question.resources) {
        for (let i = 0; i < question.resources.length; i++) {
          existingResources.push({
            name: question.resources[i].resource_link.split('/').pop(),
            resource_type: 'image', // TODO: Eventually able to add other types of files
            text: undefined, // TODO: Add way for users to add caption text to an image
            resource_link: question.resources[i].resource_link,
          });
        }
      }
      // For the staged files, encode files as strings so we don't need to use form data
      const resources = [...existingResources];
      const fileReads = [];
      if (question.stagedFiles) {
        for (let j = 0; j < question.stagedFiles.length; j++) {
          fileReads.push(this.getBase64(question.stagedFiles[j]));
        }
      }
      return Promise.all(fileReads).then(res => {
        for (let i = 0; i < res.length; i++) {
          // Bit before the comma is the encoding e.g. data:image/png;base64
          const base64String = res[i].split(',').pop();
          resources.push({
            name: question.stagedFiles[i].name,
            resource_type: 'image', // TODO: Eventually able to add other types of files
            text: undefined, // TODO: Add way for users to add caption text to an image
            file_content: base64String,
            content_type: question.stagedFiles[i].type,
          });
        }
        // Return resources when promise resolves
        return resources;
      });
    },
    formatQuestions() {
      return this.questions.map(q => ({
        id: q.id || null,
        number: q.number,
        question: q.question,
        hint: q.hint || null,
        select_all_that_apply: q.answers.filter(x => x.is_correct).length > 1,
        resources: q.resources,
        answers: q.answers.map(a => ({
          id: a.id || null,
          answer: a.answer,
          is_correct: a.is_correct,
        })),
      }));
    },
    formatQuestionsForUpload(includeId) {
      const qs = [];
      return Promise.all(this.questions.map(q => this.formatResourcesForUpload(q))).then(uploadableResources => {
        for (let i = 0; i < this.questions.length; i++) {
          const q = this.questions[i];
          if (includeId) {
            qs.push({
              id: q.id || null,
              number: q.number,
              question: q.question,
              hint: q.hint || null,
              select_all_that_apply: q.answers.filter(x => x.is_correct).length > 1,
              resources: uploadableResources[i],
              answers: q.answers.map(a => ({
                id: a.id || null,
                answer: a.answer,
                is_correct: a.is_correct,
              })),
            });
          } else {
            qs.push({
              number: q.number,
              question: q.question,
              hint: q.hint || null,
              select_all_that_apply: q.answers.filter(x => x.is_correct).length > 1,
              resources: uploadableResources[i],
              answers: q.answers.map(a => ({
                answer: a.answer,
                is_correct: a.is_correct,
              })),
            });
          }
        }
      }).then(() => qs);
    },
    getCreatePayload() {
      // No IDs - everything is created fresh
      return this.formatQuestionsForUpload(false).then(formattedQuestions => ({
        name: this.name,
        description: this.description,
        tags: this.tags,
        questions: formattedQuestions,
      }));
    },
    getUpdatePayload() {
      // Retain IDs for updating existing questions / answers
      return this.formatQuestionsForUpload(true).then(formattedQuestions => ({
        name: this.name,
        description: this.description,
        questions: formattedQuestions,
        tags: this.tags,
      }));
    },
    createQuiz() {
      this.$logger.info('Creating new quiz', {
        name: this.name,
        description: this.description,
        questionCount: this.questions.length,
        tags: this.tags,
      }, true);
      this.creationInProgress = true;
      this.getCreatePayload().then(payload => {
        this.$http.post('/api/curriculum/admin/quiz', payload).then(res => {
          this.$ktoast.success(`Created new quiz: ${res.data.quiz_id}`);
          this.$logger.info('Successfully created new quiz', {
            quizId: res.data.quiz_id,
            name: this.name,
            questionCount: this.questions.length,
            tags: this.tags,
          });
          this.$emit('update:quiz');
          this.resetForm();
        }).catch(err => {
          this.$logger.error('Error creating new quiz', undefined, err);
          this.showError(err);
        }).then(() => {
          this.creationInProgress = false;
        });
      });
    },
    updateQuiz() {
      this.$logger.info('Updating existing quiz', {
        quizId: this.quiz.id,
        name: this.name,
        description: this.description,
        questionCount: this.questions.length,
        tags: this.tags,
      }, true);
      this.creationInProgress = true;
      this.getUpdatePayload().then(payload => {
        this.$http.put(`/api/curriculum/admin/quiz/${this.quiz.id}`, payload).then(() => {
          this.$ktoast.success(`Updated quiz: '${this.name}'`);
          this.$logger.info('Successfully updated existing quiz', {
            quizId: this.quiz.id,
            name: this.name,
            questionCount: this.questions.length,
            tags: this.tags,
          });
          this.$emit('update-quiz');
        }).catch(err => {
          this.$logger.error('Error updating quiz', { quizId: this.quiz.id, payload }, err);
          this.showError(err);
        }).then(() => {
          this.creationInProgress = false;
        });
      });
    },
    resetForm() {
      this.name = '';
      this.description = '';
      this.questions = [];
      this.tags = [];
    },
    handleSubmit() {
      if (this.editMode) {
        this.updateQuiz();
      } else {
        this.createQuiz();
      }
    },
  },
};
</script>
