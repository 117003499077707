<template>
    <div class="dash-programme-modules">
      <k-nav-tabs :tabs=tabs
                  :current-tab="tab"
                  :useRouter="false"
                  @tab-selected="tab = $event"></k-nav-tabs>
      <span v-if="message">{{ message }}</span>
      <div v-if="showModules">
        <div v-for="mod in trackedModules" :key="mod.id">
          <module-card
            :is-learner-support="isLearnerSupport"
            :programme-module="mod"
            :programme-member-id="programmeMemberId">
          </module-card>
        </div>
      </div>
      <div v-if="showAchievements">
        <div v-if="badges.length">
          <span class="badges-header">
            <h3>Badges</h3>
          </span>
          <k-progress class="badgeProgress"
                      :percentage="percentOfBadgesAchieved"
                      :customText="' of badges awarded'"
                      ariaLabel="Percentage of badges achieved"
                      :precision="2"></k-progress>
          <div v-if="revokingAchievement.badge || !badgesReady" class="revoking-achievement badges">
            <i class="fas fa-spinner fa-spin"/>
          </div>
          <div v-else>
            <achievement-award-card v-for="badge in orderedBadges"
                                    :key="badge.id"
                                    :achievement="badge"
                                    :revocable="true"
                                    @revoke-achievement="confirmRevokeAchievement"></achievement-award-card>
          </div>
        </div>
        <div v-if="programmeCertificate">
          <span class="certificate-header">
            <h3>Certificate</h3>
            <button class="btn-primary award-certificate-button"
                    @click="confirmCertificateAward()"
                    v-if="!certificateAwarded && this.$permissions.hasPermission('award_programme_certificate')">Award Certificate</button>
          </span>
          <div v-if="revokingAchievement.certificate || !certificatesReady" class="revoking-achievement certificate">
            <i class="fas fa-spinner fa-spin"/>
          </div>
          <div v-else>
            <achievement-award-card
              :achievement="programmeCertificate"
              :revocable="true"
              :achievement-type="'certificate'"
              @revoke-achievement="confirmRevokeAchievement"></achievement-award-card>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped>
  .badgeProgress {
    margin-bottom: 20px;
  }

  .badges-header > h3 {
    margin-bottom: 20px;
  }

  .certificate-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .revoking-achievement {
    display: flex;
    justify-content: center;
  }
</style>

<script>
import { sortObjectArray } from '../../../modules/sort-by-object-property';
import ErrorMixin from '../../../mixins/error-mixins';
import ModuleCard from './dashboard-module-card.vue';
import AchievementAwardCard from '../../achievements/achievement-award-card.vue';
import KNavTabs from '../../../components/k-nav-tabs.vue';
import KProgress from '../../../components/k-progress.vue';

export default {
  components: {
    KProgress,
    KNavTabs,
    AchievementAwardCard,
    ModuleCard,
  },

  props: {
    programmeId: Number,
    programmeName: String,
    programmeMemberId: Number,
    isLearnerSupport: Boolean,
    studentId: Number,
  },

  mixins: [ErrorMixin],

  data() {
    return {
      modules: [],
      modulesReady: false,
      badges: [],
      badgesReady: false,
      programmeCertificate: undefined,
      revokingAchievement: { badge: false, certificate: false },
      certificatesReady: false,
      tab: 'curriculum',
      tabs: [
        { key: 'curriculum', name: 'Curriculum' },
        { key: 'milestone', name: 'Milestones' },
        { key: 'achievements', name: 'Achievements' },
      ],
    };
  },

  mounted() {
    this.getProgrammeData();
  },

  watch: {
    programmeId() {
      this.getProgrammeData();
    },
  },

  computed: {
    trackedModules() {
      return this.modules.filter(x => x.track === this.tab);
    },
    orderedBadges() {
      return sortObjectArray(this.badges, 'date_issued', true);
    },
    percentOfBadgesAchieved() {
      if (!this.badges?.length) {
        return 0;
      }
      return (this.badges.filter(x => x.has_been_awarded).length / this.badges.length) * 100;
    },
    showModules() {
      return this.tab === 'curriculum' || this.tab === 'milestone';
    },
    showAchievements() {
      return this.tab === 'achievements';
    },
    message() {
      if (this.showModules && !this.trackedModules.length) {
        return `There are no ${this.tab} modules in this programme`;
      }
      if (this.showAchievements && !this.badges?.length && !this.programmeCertificate) {
        return 'There are no achievements to be awarded in this programme';
      }
      return undefined;
    },
    certificateAwarded() {
      return this.programmeCertificate?.has_been_awarded;
    },
  },

  methods: {
    getProgrammeData() {
      this.getModules();
      this.getBadges();
      this.getCertificates();
    },
    getModules() {
      this.$logger.info('Getting modules for programme', { progId: this.programmeId }, true);
      this.modulesReady = false;
      this.modules = [];
      this.$http.get(`/api/curriculum/admin/programmes/${this.programmeId}/modules`).then(res => {
        this.modules = sortObjectArray(res.data.modules, 'number');
        this.originalModules = sortObjectArray(JSON.parse(JSON.stringify(res.data.modules)), 'number');
        this.$logger.info('Got modules for programme', { progId: this.programmeId });
      }).catch(err => {
        this.$logger.error('Error getting modules for programme', { progId: this.programmeId }, err);
        this.showError(err);
      }).then(() => {
        this.modulesReady = true;
      });
    },
    getBadges() {
      this.$logger.info('Getting badges for user in programme', { progId: this.programmeId, userId: this.studentId }, true);
      this.badgesReady = false;
      this.badges = [];
      this.$http.get(`/api/curriculum/admin/programmes/${this.programmeId}/badges/user/${this.studentId}`).then(res => {
        this.badges = res.data;
        this.$logger.info('Got badges for user in programme', { progId: this.programmeId, userId: this.studentId });
      }).catch(err => {
        this.$logger.error('Error getting badges for user in programme', { progId: this.programmeId, userId: this.studentId }, err);
        this.showError(err);
      }).then(() => {
        this.badgesReady = true;
      });
    },
    getCertificates() {
      this.$logger.info('Getting certificate for user in programme', { progId: this.programmeId, userId: this.studentId }, true);
      this.certificatesReady = false;
      this.programmeCertificate = undefined;
      this.$http.get(`/api/curriculum/admin/programmes/certificates/user/${this.studentId}`).then(res => {
        this.programmeCertificate = res.data.find(c => c.programme_id === this.programmeId);
        this.$logger.info('Got certificate for user in programme', { progId: this.programmeId, userId: this.studentId });
      }).catch(err => {
        this.$logger.autowarn('Error getting certificate for user in programme', { progId: this.programmeId, userId: this.studentId }, err);
        this.showError(err);
      }).then(() => {
        this.certificatesReady = true;
      });
    },

    toastMessage() {
      return 'You are about to award this certificate. The learner will be able to view it in their profile and share '
          + 'it publicly.';
    },

    confirmCertificateAward() {
      this.$ktoast.confirmToast(this.toastMessage(), 'warning', this.awardCertificate);
    },

    awardCertificate() {
      this.$Loading.start();
      const payload = {
        programme_id: this.programmeId,
        user_id: this.studentId,
      };
      this.$logger.info('Awarding certificate', { programmeId: this.programmeId }, true);
      this.$http.put('/api/curriculum/programmes/certificates/user', payload).then(() => {
        this.$logger.info('Awarded certificate', { programmeId: this.programmeId });
        this.getCertificates();
      }).catch(err => {
        this.showError(err);
        this.$logger.autowarn('Error awarding certificate manually', { programmeId: this.programmeId }, err);
      }).then(() => {
        this.$Loading.finish();
      });
    },

    revokeAchievement(achievementId, achievementType) {
      let endpoint;
      this.revokingAchievement[achievementType] = true;
      if (achievementType === 'badge') {
        endpoint = `/api/curriculum/programme_badge/${achievementId}/user/${this.studentId}`;
      } else {
        endpoint = `/api/curriculum/programme/${achievementId}/user/${this.studentId}/certificate`;
      }
      this.$logger.info(`Revoking ${achievementType}`, { achievementId, studentId: this.studentId });
      this.$http.delete(endpoint).then(() => {
        this.$logger.info(`Revoked ${achievementType}`, { achievementId, studentId: this.studentId });
        if (achievementType === 'badge') {
          this.getBadges();
        } else {
          this.getCertificates();
        }
      }).catch(err => {
        this.showError(err);
        this.$logger.autowarn(`Error revoking ${achievementType}`, { achievementId, studentId: this.studentId }, err);
      }).then(() => {
        this.revokingAchievement[achievementType] = false;
      });
    },

    confirmRevokeAchievement(achievementId, achievementType) {
      this.$ktoast.confirmToast(`You are about to revoke this ${achievementType}.`, 'warning', () => {
        this.revokeAchievement(achievementId, achievementType);
      });
    },
  },
};
</script>
