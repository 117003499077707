import { MODULE_TYPES } from '../constants';

export default {
  computed: {
    isBlueprint() {
      return this.moduleType !== 'module';
    },
    moduleTypeMeta() {
      return MODULE_TYPES[this.moduleType];
    },
    programmeIdField() {
      return this.moduleTypeMeta.programmeIdField;
    },
    moduleIdField() {
      return this.moduleTypeMeta.idField;
    },
  },
};
