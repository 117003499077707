<template>
  <div v-if="ready && module" class="content-with-spacing" id="manage-module">
    <h1>{{ module.name }}</h1>
    <div class="row">
      <!-- Asset release navigation -->
      <aside class="release-nav">
        <ul>
          <li>
            <router-link :to="metaTo">
              <i class="k-asset-icon fas fa-bullseye"></i>
              <b>Module Meta</b>
              <i class="fas fa-angle-right"></i>
            </router-link>
          </li>
          <li>
            <router-link :to="chapterTo">
              <i class="k-asset-icon fas fa-book"></i>
              <b>Chapters</b>
              <i class="fas fa-angle-right"></i>
            </router-link>
          </li>
          <!-- Assets -->
          <li v-for="assetType in Object.keys(ASSET_TYPES)" :key="assetType">
            <router-link :to="assetTo(assetType)">
              <i class="k-asset-icon" :class="getAssetIcon(assetType)"></i>
              <b>{{ plural(ASSET_TYPES[assetType].prettyName) }}</b>
              <i class="fas fa-angle-right"></i>
            </router-link>
          </li>
        </ul>
      </aside>
      <!-- Release panel -->
      <section class="release-panel">
        <div class="panel panel-default">
          <router-view v-bind="pageProps($route.name)"
            @metaUpdated="getModuleData"
            @chaptersUpdated="getModuleData"
            @assetsUpdated="assetCallback"
          ></router-view>
        </div>
      </section>
    </div>
  </div>
</template>

<style>
#manage-module .k-editable-table-container {
  overflow: auto;
}

#manage-module .k-edit-table-th:first-child {
  min-width: 200px;
}

#manage-module .k-edit-table-th:last-child .k-edit-table-header .header-left {
  justify-content: center;
}

#manage-module .k-editable-table-pagination.panel-pagination.row {
  margin: 0 0 15px;
}

#manage-module .k-editable-table-container .cell-row.date,
#manage-module .k-editable-table-container .cell-row.datetime {
  min-width: 220px;
}

#manage-module .panel-pagination.row {
  position: sticky;
  left: 0;
}

#manage-module .splitpanes.custom-theme.modal-theme .splitpanes__splitter {
  min-height: 500px;
  height: unset;
}

#manage-module .splitpanes.custom-theme.modal-theme .splitpanes__pane.left-pane {
  max-height: 90vh;
  overflow-y: auto;
}

#manage-module .table-controls {
  display: flex;
  justify-content: right;
  gap: 10px;
}

/*  Tables */
#manage-module .release-table {
  margin-left: 15px;
}

#manage-module .release-table .k-editable-table-container {
  margin-bottom: 20px;
  min-height: 300px;
}

#manage-module .release-table .btn-remove {
  background-color: transparent;
  padding: 5px 8px;
}

#manage-module .release-table .btn-remove i {
  color: var(--kate-danger-alt);
}

#manage-module .release-table .btn-remove:hover i {
  color: var(--kate-danger);
}

#manage-module .release-table td:last-child {
  text-align: center;
}

.k-text-search.filter-input {
  margin: 0 15px;
}

@media screen and (max-width: 767px) {
  #manage-module .release-table .k-editable-table-container,
  #k-released-release-table-container table {
    overflow: auto;
  }
}

.release-asset-container tr.k-editable-table-row.unreleased:nth-child(odd) {
  background-color: var(--kate-success);
}

.release-asset-container tr.k-editable-table-row.unreleased:nth-child(even) {
  background-color: var(--kate-success-dark);
}

/* For module release */
.release-asset-container ul {
  padding: 0 !important;
}

.release-asset-container li.list-items {
  color: unset;
  list-style: none;
}

.release-asset-container li.list-items .item {
  width: 90%;
  padding: 5px;
  display: block;
  margin: 8px auto 0;
  border-radius: 3px;
  transition: all 0.1s ease;
  color: var(--kate-type-white);
  background-color: var(--kate-primary-dark);
}

.release-asset-container li.list-items .item:hover {
  background-color: var(--kate-panel-alt);
}

.release-asset-container li.list-items .item.selected {
  transform: translateX(20px);
  background-color: var(--kate-background-body);
}

.release-asset-container .k-search-dropdown-menu .btn-primary {
  padding: 5px 12px;
}

#manage-module .loading {
  font-size: 2em;
  padding: 15px;
  text-align: center;
}

#manage-module .loading .fa-spin {
  background: var(--right-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

<style scoped>
#manage-module h1 {
  margin-top: 0;
}

.button-container {
  height: 55px;
}

.learning-outcomes-mock ol li {
  display: block;
  margin-top: 20px;
}

.learning-outcomes-mock ol li i {
  color: var(--kate-type-accent);
}

.row {
  gap: 15px;
  display: flex;
  margin: 0;
}

.release-nav ul .fa-angle-right {
  margin-top: 3px;
  margin-left: 1em;
}

/* Release nav */
.release-nav {
  width: 48px;
  height: 100%;
  overflow: hidden;
  transition: overflow 0.3s ease-in-out, width 0.4s ease-in-out;
  background-color: var(--kate-panel);
  box-shadow: var(--box-shadow);
  border-radius: 15px;
}

.release-nav:hover {
  width: 250px;
  overflow: visible;
  transition: overflow 0.3s ease-in-out, width 0.4s ease-in-out;
}

.release-nav b,
.release-nav .fa-angle-right {
  opacity: 0;
  transition: opacity 0.4s;
  transition-delay: 2ms;
}

.release-nav:hover b,
.release-nav:hover .fa-angle-right {
  opacity: 1;
  transition: opacity 0.4s;
  transition-delay: 140ms;
}

.release-nav ul {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.release-nav ul li {
  list-style: none;
  padding: 0;
}

.release-nav ul li a {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 15px;
  color: var(--kate-type-primary);
}

.release-nav ul li a:active,
.release-nav ul li a.router-link-exact-active {
  text-decoration: none;
}

.release-nav ul li:hover {
  background-color: var(--kate-background-alpha);
  color: var(--kate-type-light);
}

.release-nav ul li:first-child,
.release-nav ul li:first-child .router-link-exact-active {
  border-radius: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.release-nav ul li .router-link-exact-active {
  color: var(--kate-primary);
  background-color: var(--kate-background-alpha);
}

.release-nav ul li a b {
  text-align: left;
  flex: 1;
}

.release-nav ul li a i {
  text-align: center;
}

/* Release panel */
.release-panel {
  flex: 1;
  width: 80%;
}

.release-panel .panel {
  padding: 15px;
  min-height: 592px;
}
</style>

<script>
import ModuleTypeMixin from '../../../mixins/module-type-mixin';
import ErrorMixin from '../../../mixins/error-mixins';
import ChaptersMixin from '../../../mixins/chapters-mixin';
import PluralMixin from '../../../mixins/plural-mixin';
import AssetIconMixin from '../../../mixins/asset-icon-mixin';
import { ASSET_TYPES } from '../../../constants';

// FEATURE-TODO: Rename this component to abstract-manage-module.vue

export default {
  mixins: [ErrorMixin, ChaptersMixin, AssetIconMixin, PluralMixin, ModuleTypeMixin],

  data() {
    return {
      programme: {},
      programmeReady: false,
      trainers: [],
      trainersReady: false,
      chapters: [],
      module: undefined,
      moduleReady: false,
      chaptersReady: false,
      ASSET_TYPES,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgrammeData();
    this.getModuleData();
    this.getTrainers();
  },

  watch: {
    ready() {
      if (this.ready) {
        this.$Loading.finish();
        this.registerCrumbs();
      } else {
        this.$Loading.start();
      }
    },
    route() {
      // Re-register crumbs (nav guard clears them on route change)
      this.registerCrumbs();
    },
  },

  computed: {
    ready() {
      let ready = this.chaptersReady && this.moduleReady;
      if (!this.isBlueprint) {
        ready = ready && this.programmeReady && this.trainersReady;
      }
      return ready;
    },
    programmeId() {
      const entityId = this.$route.params[this.programmeIdField];
      return entityId ? parseInt(entityId, 10) : null;
    },
    moduleId() {
      const entityId = this.$route.params[this.moduleIdField];
      return entityId ? parseInt(entityId, 10) : null;
    },
    moduleType() {
      switch (true) {
        case this.$route.name.startsWith('programme_blueprint_module'):
          return 'programme_blueprint_module';
        case this.$route.name.startsWith('module_blueprint'):
          return 'module_blueprint';
        default:
          return 'module';
      }
    },
    programmeEndpoint() {
      return `${this.moduleTypeMeta.programmeApiRoot}/${this.programmeId}`;
    },
    moduleEndpoint() {
      return `${this.moduleTypeMeta.moduleApiRoot}/${this.moduleId}`;
    },
    chapterEndpoint() {
      return `${this.moduleTypeMeta.moduleApiRoot}/${this.moduleId}/chapters`;
    },
    metaTo() {
      return { name: `${this.moduleType}_meta_manager` };
    },
    chapterTo() {
      return { name: `${this.moduleType}_chapter_manager` };
    },
    route() {
      return this.$route;
    },
    moduleCrumbs() {
      return [
        {
          text: 'Manage programmes',
          path: {
            name: 'programmes_overview',
          },
        },
        {
          text: this.programme.name,
          path: {
            name: 'manage_programme_content',
            params: { programmeId: this.programmeId },
          },
        },
        {
          text: this.module.name,
          active: true,
        },
      ];
    },
    programmeBlueprintModuleCrumbs() {
      return [
        {
          text: 'Manage programme blueprints',
          path: {
            name: 'curriculum_blueprints_programmes',
          },
        },
        {
          text: this.programme.name,
          path: {
            name: 'curriculum_manage_programme_blueprint',
            params: { programmeBlueprintId: this.programmeBlueprintId },
          },
        },
        {
          text: this.module.name,
          active: true,
        },
      ];
    },
    moduleBlueprintCrumbs() {
      return [
        {
          text: 'Manage module blueprints',
          active: false,
          path: {
            name: 'curriculum_blueprints_modules',
          },
        },
        {
          text: this.module.name,
          active: true,
        },
      ];
    },
    logContext() {
      const logContext = {};
      logContext[`${this.moduleTypeMeta.moduleIdField}`] = this.moduleId;
      if (this.moduleTypeMeta.programmeIdField) {
        logContext[`${this.moduleTypeMeta.programmeIdField}`] = this.programmeId;
      }
      return logContext;
    },
  },

  methods: {
    assetTo(assetType) {
      return {
        name: `${this.moduleType}_asset_release`,
        params: { assetType },
      };
    },
    pageProps(pageName) {
      switch (pageName) {
        case `${this.moduleType}_meta_manager`:
          return {
            module: this.module,
            moduleType: this.moduleType,
          };
        case `${this.moduleType}_chapter_manager`:
          return {
            moduleId: this.moduleId,
            moduleType: this.moduleType,
            chapters: this.chapters,
          };
        case `${this.moduleType}_asset_release`:
          return this.assetPageProps(this.$route.params.assetType);
        default:
          return {};
      }
    },
    assetPageProps(assetType) {
      const props = {
        moduleId: this.moduleId,
        moduleType: this.moduleType,
        assetType,
        releasedAssets: this.assetsOfType(assetType),
      };
      if (this.isBlueprint) {
        return props;
      }
      props.programmeGroups = this.programme?.programme_groups || [];
      switch (assetType) {
        case 'pak':
          return {
            ...props,
            requireWithdrawalConfirmation: true,
          };
        case 'learning_unit':
          return {
            ...props,
            requireWithdrawalConfirmation: true,
          };
        case 'quiz':
          return {
            ...props,
            requireWithdrawalConfirmation: true,
          };
        case 'calendar_event':
          return {
            ...props,
            moduleReleaseDate: this.module.release_date,
            moduleCompletionDate: this.module.expected_completion_date,
            useTimezones: !this.isBlueprint,
            trainers: this.trainers,
          };
        // TODO: Questionnaires should have a check to see if there have been submissions - DATA LOSS RISK
        default:
          return props;
      }
    },
    registerCrumbs() {
      let crumbs = [];
      if (!this.module) {
        return;
      }
      switch (this.moduleType) {
        case 'module_blueprint':
          crumbs = this.moduleBlueprintCrumbs;
          break;
        case 'module':
          crumbs = this.moduleCrumbs;
          break;
        case 'programme_blueprint_module':
          crumbs = this.programmeBlueprintModuleCrumbs;
          break;
        default:
          crumbs = [];
      }
      this.$crumbs.register(crumbs);
    },
    getProgrammeData() {
      if (this.moduleType === 'module_blueprint') {
        // Module blueprints are not coupled to a programme entity
        return;
      }
      this.programmeReady = false;
      const entityName = this.moduleTypeMeta.parentName;
      this.$logger.info(`Getting ${entityName}`, this.logContext);
      this.$http.get(this.programmeEndpoint).then(res => {
        this.$logger.info(`Successfully fetched ${entityName}`, this.logContext);
        this.programme = res.data;
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          this.$router.push({ name: '404' });
        } else if (this.$http.isWarning(err)) {
          this.$logger.warn(`Could not retrieve ${entityName}`, this.logContext, err);
          this.showError(err);
        } else {
          this.$logger.error(`Error fetching ${entityName}`, this.logContext, err);
        }
      }).then(() => {
        this.programmeReady = true;
      });
    },
    getTrainers() {
      if (this.isBlueprint) {
        return;
      }
      this.$logger.info('Getting all trainers');
      this.trainersReady = false;
      this.$http.get('/api/profile/trainer').then(result => {
        this.$logger.info('Got trainers');
        this.trainers = result.data.trainers;
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          this.$logger.warn('No trainers found');
        } else {
          this.showError(err);
          this.$logger.autowarn('Not able to get trainers', undefined, err);
        }
      }).then(() => {
        this.trainersReady = true;
      });
    },
    getModuleData() {
      this.getModuleChapters();
      this.getModule();
    },
    getModule() {
      this.$logger.info(`Getting ${this.moduleTypeMeta.name}`, this.logContext);
      this.moduleReady = false;
      return this.$http.get(this.moduleEndpoint).then(res => {
        this.module = res.data;
        this.$logger.info(`Got ${this.moduleTypeMeta.name}`, this.logContext);
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          this.$router.push({ name: '404' });
        } else {
          this.$logger.autowarn(`Error getting ${this.moduleTypeMeta.name}`, this.logContext, err);
          this.showError(err);
        }
      }).then(() => {
        this.moduleReady = true;
        this.$Loading.finish();
      });
    },
    getModuleChapters() {
      this.$logger.info('Getting chapters', this.logContext);
      this.chaptersReady = false;
      return this.$http.get(this.chapterEndpoint).then(res => {
        this.$logger.info('Got chapters', this.logContext);
        this.chapters = res.data.chapters;
      }).catch(err => {
        this.$logger.autowarn('Error getting chapters', this.logContext, err);
        this.showError(err);
      }).then(() => {
        this.chaptersReady = true;
      });
    },
    calendarEventEmailFailures(tasks) {
      if (!this.isBlueprint) {
        if (!tasks) {
          return;
        }
        // Cloud tasks fail when scheduling tasks for events that are more than 30 days in the future
        // We have a workaround for this, so we will exclude the error messages related to this as users don't need to know about it
        const excludedErrorMessage = 'Schedule time must be no more than 720h in the future.';
        let failureList = tasks.filter(task => task.error && task.error.indexOf(excludedErrorMessage) < 0).map(
          task => this.events.find(ce => ce.calendar_event_id === task.calendar_event_id)?.name,
        );
        failureList = failureList.filter((task, pos) => failureList.indexOf(task) === pos);
        if (failureList.length) {
          const toastMessage = `Emails failed to be scheduled for the following events: ${failureList.join(', ')}`;
          this.$ktoast.warning(`${toastMessage}`, { goAway: 5000 });
        }
      }
    },
    assetCallback(assetType, callbackData) {
      switch (assetType) {
        case 'calendar_event':
          this.calendarEventEmailFailures(callbackData);
          break;
        default:
          break;
      }
      this.getModuleData();
    },
  },
};
</script>
