<template>
  <div id="create-accounts-component">
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div v-if="errorUsers.length > 0" class="k-create-accounts-alert alert alert-danger">
          <b>Error: </b> Unable to create some accounts<br>
          <div>
            <button id="create-accounts-retry-button" v-if="!hasRetried" class="btn btn-success" @click="retryFailedUsers">
              <i class="fas fa-undo"></i>
              Retry Failed Users</button>
            <button id="create-accounts-export-errors-button" class="btn btn-success" @click="exportFailedUsers">
              <i class="fas fa-file-download"></i>
              Export Failed Users</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-xs-12">
      <k-table :hideable="false" v-if="!ready" :panel="false" :rows="users" :headers="kableHeaders" :max="30"></k-table>
      <k-table v-else :key="2" :hideable="false" :panel="false" :rows="refinedUsers" :max="30" :headers="kableRegisteredHeaders"></k-table>
      </div>
    </div>
    <div class="row create-acc-footer">
      <div class="col-md-12 col-xs-12">
        <span class="loader-icon"><i class="fa fa-spinner fa-spin" v-if="loading"></i></span>
        <button class="btn btn-success" v-if="!disableCreateButton" @click="registerUsers">
          Create Accounts
          <i class="fa-solid fa-plus"></i>
        </button>
        <div v-if="ready && successUsers.length > 0" class="k-create-account-right">
          <button class="btn btn-success" @click="finish"><i class="fas fa-check"></i> Finish</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.panel-body .row {
  display: flex;
}

.create-acc-footer {
  text-align: right;
  padding-top: 10px;
  margin-top: 20px;
  border-top: 2px solid var(--kate-background-body-alpha);
}

.k-table-container {
  margin-top: 1em;
}

.loader-icon i {
  font-size: 35px;
  color: var(--kate-primary);
}

.k-create-accounts-alert {
  margin-top: 1em;
  max-width: 30em;
}

.k-table-key-success-true {
  color: var(--kate-success);
}

.k-table-key-success-false {
  color: var(--kate-danger);
}
</style>

<script>
import CSVMixin from '../../../mixins/csv-mixins';
import KTable from '../../../components/k-table.vue';

const MAX_PAYLOAD_SIZE = 30;

export default {
  mixins: [CSVMixin],
  components: {
    'k-table': KTable,
  },
  props: {
    users: {
      type: Array,
      required: true,
      notes: 'user email format, e.g. [{ name: somename, email: some@mail.com }]',
    },
    programme: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.refinedUsers = this.processUsers();
  },
  data() {
    return {
      userStatus: [],
      refinedUsers: [],
      hasRetried: false,
      ready: false,
      MAX_PAYLOAD_SIZE,
      loading: false,
      disableCreateButton: false,
    };
  },
  computed: {
    successUsers() {
      if (!this.ready) {
        return [];
      }
      const output = [];
      for (let i = 0; i < this.refinedUsers.length; i++) {
        if (this.refinedUsers[i].success) {
          output.push(this.refinedUsers[i]);
        }
      }
      return output;
    },
    errorUsers() {
      if (!this.ready) {
        return [];
      }
      const output = [];
      for (let i = 0; i < this.refinedUsers.length; i++) {
        if (!this.refinedUsers[i].success) {
          output.push(this.refinedUsers[i]);
        }
      }
      return output;
    },
    kableRegisteredHeaders() {
      const headers = {
        name: {
          name: 'Name',
          filterable: true,
        },
        email: {
          name: 'Email',
          filterable: true,
        },
        groupTags: {
          name: 'Groups',
          filterable: true,
          type: 'tags',
        },
        domainName: {
          name: 'Domain',
          filterable: true,
        },
        companyName: {
          name: 'Company',
          filterable: true,
        },
        success: {
          name: 'Status',
          type: 'boolean',
          sortable: true,
          options: {
            true: '<i class="fas fa-check-circle"></i><span class="toast-text">Account Created</span>',
            false: '<i class="fas fa-exclamation-triangle"></i><span class="toast-text">Error</span>',
          },
        },
      };
      if (!this.programmeHasGroups) {
        delete headers.groupTags;
      }
      return headers;
    },
    kableDownloadHeaders() {
      return {
        creation_status: {
          name: 'creation_status',
        },
        error: {
          name: 'error',
        },
        error_code: {
          name: 'error_code',
        },
        programme_id: {
          name: 'programme_id',
        },
        ...this.kableRegisteredHeaders,
      };
    },
    kableHeaders() {
      const headers = {
        name: {
          name: 'Name',
          filterable: true,
        },
        email: {
          name: 'Email',
          filterable: true,
        },
        groupTags: {
          name: 'Groups',
          filterable: true,
          type: 'tags',
        },
        domainName: {
          name: 'Domain',
          filterable: true,
        },
        companyName: {
          name: 'Company',
          filterable: true,
        },
      };
      if (!this.programmeHasGroups) {
        delete headers.groupTags;
      }
      return headers;
    },
    programmeHasGroups() {
      return Boolean(this.programme.programme_groups && this.programme.programme_groups.length > 0);
    },
  },
  methods: {
    processResults(results) {
      let tempId;
      let entry;
      for (let i = 0; i < results.length; i++) {
        for (let j = 0; j < results[i].data.length; j++) {
          tempId = results[i].data[j].temp_id;
          entry = results[i].data[j];
          entry.success = entry.creation_status >= 200 && entry.creation_status < 300;
          this.refinedUsers[tempId] = entry;
        }
      }
      this.$logger.info('Successfully added users to programme', { programmeId: this.programme.id, users: this.refinedUsers }, true);
    },
    processUsers() {
      return this.users.map((val, index) => ({
        temp_id: index,
        full_name: val.name,
        ...val,
      }));
    },
    partitionUsers(users) {
      const output = [];
      for (let i = 0, j = users.length; i < j; i += this.MAX_PAYLOAD_SIZE) {
        output.push(users.slice(i, i + this.MAX_PAYLOAD_SIZE));
      }
      return output;
    },
    registerUserCall(users) {
      const userPayload = users.map(x => ({
        programmes: [{
          id: this.programme.id,
          groups: x.groups,
        }],
        ...x,
      }));
      return this.$http.post('/api/profile/user/bulk', {
        users: userPayload,
      });
    },
    registerCall(chunks) {
      this.ready = false;
      this.loading = true;
      const calls = [];
      for (let i = 0; i < chunks.length; i++) {
        calls.push(this.registerUserCall(chunks[i]));
      }
      Promise.all(calls).then(responses => {
        this.processResults(responses);
        this.ready = true;
        this.loading = false;
      });
    },
    exportFailedUsers() {
      this.downloadCsvFromTable(this.kableDownloadHeaders, this.errorUsers, 'error_users.csv');
    },
    retryFailedUsers() {
      const chunks = this.partitionUsers(this.errorUsers);
      this.registerCall(chunks);
      this.hasRetried = true;
    },
    registerUsers() {
      this.$logger.info('Adding users to programme', { programmeId: this.programme.id, users: this.refinedUsers }, true);
      this.disableCreateButton = true;
      const chunks = this.partitionUsers(this.refinedUsers);
      this.registerCall(chunks);
    },
    finish() {
      this.$emit('finish', this.successUsers);
    },
  },
};
</script>
