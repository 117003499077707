<template>
  <div>
    <h1>Meeting Overview</h1>
    <asset-overview v-if="ready"
      asset-icon="fa-headset"
      :assets="sortedMeetings"
      :kable-headers="kableHeaders"
      :kable-rows="kableRows"
      :show-archived-assets="showArchived"
      @tablecallback="tableCallback"
      @archive-toggle="updateArchiveToggle"
    >
      <template #new-asset>
        <add-meetings @meeting-added="getMeetings"></add-meetings>
      </template>
      <template #preview-asset>
        <update-meetings :meeting="meetingToUpdate"
          @close="closeEditModal"
          @update="getMeetings">
        </update-meetings>
      </template>
    </asset-overview>
  </div>
</template>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import PageReadyMixin from '../../../../mixins/page-ready-mixin';
import getIcon from '../../../../modules/kate-table-icons';
import { sortObjectArray } from '../../../../modules/sort-by-object-property';
import AssetOverview from '../asset-overview.vue';
import AddMeetings from './add-meeting.vue';
import UpdateMeetings from './update-meeting.vue';
import ArchiveToastConfirmMixin from '../archive-toast-confirm-mixin';

export default {
  components: {
    AssetOverview,
    AddMeetings,
    UpdateMeetings,
  },

  mixins: [ErrorMixin, PageReadyMixin, ArchiveToastConfirmMixin],

  data() {
    return {
      meetingsReady: false,
      meetings: [],
      meetingToUpdate: undefined,
      showArchived: false,
    };
  },

  beforeMount() {
    this.getMeetings();
  },

  computed: {
    ready() {
      return this.meetingsReady;
    },
    sortedMeetings() {
      return sortObjectArray(this.meetings.slice(), 'name', false);
    },

    kableHeaders() {
      return {
        edit: {
          name: 'Edit',
          type: 'action',
        },
        title: {
          name: 'Title',
          filterable: true,
          sortable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
        },
        completionTypes: {
          name: 'Completion Types',
        },
        displayTags: {
          name: 'Tags',
          type: 'tags',
          filterable: true,
          sortable: true,
        },
        archive: {
          name: 'Archive',
          type: 'action',
          sortable: true,
        },
        delete: {
          name: 'Delete',
          type: 'action',
        },
      };
    },

    kableRows() {
      return this.sortedMeetings.map((val, index) => ({
        edit: {
          key: index,
          text: getIcon({ icon: 'edit' }),
        },
        title: {
          key: index,
          text: val.name,
          path: {
            name: 'curriculum_asset_details',
            params: {
              assetId: val.id,
              assetType: 'meeting',
            },
          },
        },
        completionTypes: this.formattedCompletionTypes(val.completion_types),
        displayTags: {
          tags: val.tags || [],
          sortBy: val.tags ? [...val.tags].sort().join(' ') : '',
        },
        archive: {
          text: this.assetArchiveButton(val.is_active),
        },
        delete: {
          key: index,
          text: getIcon({ color: 'var(--kate-danger-alt)', icon: 'trash' }),
        },
        ...val,
      })).filter(mt => (this.showArchived || mt.is_active));
    },
  },

  methods: {
    closeEditModal() {
      this.meetingToUpdate = undefined;
    },

    updateArchiveToggle(val) {
      this.showArchived = val;
    },

    formattedCompletionTypes(completionTypes) {
      if (completionTypes && completionTypes.length) {
        return `<ul>${completionTypes.map(_type => ['<li>', _type, '</li>'].join('')).join('')}</ul>`;
      }
      return '';
    },

    getMeetings() {
      this.$logger.info('Getting meetings');
      this.$Loading.start();
      this.meetingsReady = false;
      this.$http.get('/api/curriculum/admin/meeting')
        .then(result => {
          this.meetings = result.data.meetings;
        }).catch(err => {
          if (err.response && err.response.status !== 404) {
            this.$logger.error('Error getting meetings', undefined, err);
            this.showError(err);
          } else {
            this.$logger.warn('No meetings found');
            this.meetings = [];
          }
        }).then(() => {
          this.meetingsReady = true;
          this.$Loading.finish();
        });
    },

    deleteConfirmationCallback(meetingId) {
      this.$logger.info('Deleting meetings', { meetingId }, true);
      this.$Loading.start();
      this.$http.delete(`/api/curriculum/admin/meeting/${meetingId}`)
        .then(() => {
          this.$logger.info('Successfuly deleted meeting', { meetingId });
          this.$ktoast.success('Meeting have been deleted');
          this.getMeetings();
        }).catch(err => {
          this.$logger.autowarn('Error deleting meeting', { meetingId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    deleteMeetings(meetingId) {
      this.$ktoast.confirmToast('You are about to delete this meeting.', 'warning', () => {
        this.deleteConfirmationCallback(meetingId);
      });
    },

    tableCallback(key, index, row, header) {
      if (header === 'archive') {
        this.tableArchiveCallback(row);
      }
      if (header === 'delete') {
        this.deleteMeetings(row.id);
      }
      if (header === 'edit') {
        const mt = this.meetings.find(x => x.id === row.id);
        if (mt && typeof mt === 'object') {
          this.meetingToUpdate = JSON.parse(JSON.stringify(mt));
        } else {
          this.meetingToUpdate = undefined;
        }
      }
    },

    archiveCallback(mId) {
      this.$logger.info('Archiving meeting', { mId }, true);
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/meeting/${mId}/archive`)
        .then(() => {
          this.$logger.info('Successfully archived meeting', { mId });
          this.$ktoast.success('Successfully archived meeting');
          this.getMeetings();
        }).catch(err => {
          this.$logger.error('Error archiving meeting', { mId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    unarchiveCallback(mId) {
      this.$logger.info('Unarchiving meeting', { mId }, true);
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/meeting/${mId}/unarchive`)
        .then(() => {
          this.$logger.info('Successfully unarchived meeting', { mId });
          this.$ktoast.success('Meeting has been unarchived');
          this.getMeetings();
        }).catch(err => {
          this.$logger.error('Error unarchiving meeting', { mId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },
  },
};
</script>
