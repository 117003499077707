<template>
  <div>
    <k-panel :hasContentToggle="false"
      title="Current Programmes"
      icon="fa-project-diagram"
    >
      <template #body>
        <section class="current-programmes-info">
          <div class="text">
            <p>To make changes to the <i class="highlight">content</i> of a programme,
            click on the link in the programme field in the table below. To change the
            <i class="highlight">name, description or other metadata</i> of a programme,
            click the edit button from the table</p>
          </div>
          <div class="archive-toggle">
            <k-toggle label="Show Archived" v-model="showArchived" :round="true" title="Check this box to display archived programmes"></k-toggle>
          </div>
        </section>
        <div id="programmes">
          <k-table
            :headers="kableHeaders"
            :rows="kableRows"
            :max="20"
            :hideable="true"
            :panel="false"
            @clicked="tableCallback"></k-table>
        </div>
      </template>
    </k-panel>
    <update-programme-modal
      :programme="programmeToUpdate"
      :products="products"
      @close="programmeToUpdate = undefined"
      @programme-updated="getProgrammes"
    ></update-programme-modal>
  </div>
</template>

<style>
.current-programmes-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current-programmes-info .text {
  width: 80%;
}

.archive-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#programmes > .k-table-container th {
  min-width: unset;
}

#programmes > .k-table-container .k-table-header-edit,
#programmes > .k-table-container .k-table-key-edit {
  text-align: center;
}

#programmes .k-table-header-programme,
#programmes .k-table-header-description,
#programmes .k-table-key-description,
#programmes .k-table-key-programme {
  min-width: 200px;
  white-space: unset;
}
</style>

<script>
import ErrorMixin from '../../mixins/error-mixins';
import UpdateProgrammeModal from './update-programme-modal.vue';
import KPanel from '../../components/k-panel.vue';
import KTable from '../../components/k-table.vue';
import KToggle from '../../components/k-toggle.vue';
import PageReadyMixin from '../../mixins/page-ready-mixin';
import { sortObjectArray } from '../../modules/sort-by-object-property';

export default {
  components: {
    UpdateProgrammeModal,
    KTable,
    KToggle,
    KPanel,
  },

  mixins: [ErrorMixin, PageReadyMixin],

  props: {
    products: {
      type: Array,
    },
  },

  data() {
    return {
      programmes: [],
      programmesReady: false,
      showCreateProgramme: false,
      programmeToUpdate: undefined,
      showArchived: false,
      showSection: true,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgrammes();
  },

  computed: {
    ready() {
      return this.programmesReady;
    },

    kableHeaders() {
      return {
        edit: {
          name: 'Edit',
          type: 'action',
        },
        programme: {
          name: 'Programme',
          filterable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
        },
        includeInReport: {
          name: 'Include in report',
        },
        product: {
          name: 'Product',
        },
        startDate: {
          name: 'Start Date',
          type: 'date',
          sortable: true,
        },
        plannedEndDate: {
          name: 'Planned End Date',
          type: 'date',
          sortable: true,
        },
        knowledgeBase: {
          name: 'Knowledge Base',
          type: 'action',
          sortable: true,
        },
        kloud: {
          name: 'KLOUD',
          type: 'action',
          sortable: true,
        },
        archive: {
          name: 'Archive',
          type: 'action',
          sortable: true,
        },
      };
    },

    kableRows() {
      return sortObjectArray(this.programmes, 'date_created', true).map((val, index) => ({
        edit: {
          key: index,
          text: '<i class="fas fa-edit"></i>',
        },
        programme: {
          text: val.name,
          path: { name: 'manage_programme', params: { programmeId: val.id } },
        },
        programmeId: val.id,
        creationDate: val.date_created,
        description: val.description || 'No description found',
        includeInReport: val.include_in_reporting ? 'yes' : 'no',
        product: this.products.find(p => p.id === val.product_id)?.name,
        startDate: val.start_date,
        plannedEndDate: val.planned_end_date,
        knowledgeBase: {
          key: index,
          enabled: val.knowledge_base_enabled,
          text: this.enabledIcon(val.knowledge_base_enabled, 'Knowledge Base'),
        },
        kloud: {
          key: index,
          enabled: val.kloud_enabled,
          text: this.enabledIcon(val.kloud_enabled, 'KLOUD'),
        },
        archive: {
          key: index,
          text: this.programmeArchiveIcon(val.is_active),
        },
        is_active: val.is_active,
      })).filter(row => (this.showArchived ? true : row.is_active));
    },
  },

  methods: {
    getProgrammes() {
      this.$logger.info('Getting programmes');
      this.programmesReady = false;
      return this.$http.get('/api/curriculum/programmes?active_only=false').then(res => {
        this.programmes = res.data.programmes;
        this.$logger.info('Successfully retrieved programmes');
      }).catch(err => {
        this.$logger.error('Error retrieving programmes', undefined, err);
        this.showError(err);
      }).then(() => {
        this.programmesReady = true;
      });
    },

    archiveProgramme(programmeId) {
      this.$logger.info('Archiving programme', { programmeId }, true);
      this.$Loading.start();
      this.$http.delete(`/api/curriculum/programmes/${programmeId}/archive`)
        .then(() => {
          this.$logger.info('Successfully archived programme', { programmeId }, true);
          this.$ktoast.success('Programme has been archived');
          this.getProgrammes();
        }).catch(err => {
          this.$logger.error('Error archiving programme', { programmeId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    unarchiveProgramme(programmeId) {
      this.$logger.info('Unarchiving programme', { programmeId }, true);
      this.$Loading.start();
      this.$http.put(`/api/curriculum/programmes/${programmeId}/unarchive`)
        .then(() => {
          this.$logger.info('Successfully un-archived programme', { programmeId }, true);
          this.$ktoast.success('Programme has been un-archived');
          this.getProgrammes();
        }).catch(err => {
          this.$logger.error('Error unarchiving programme', { programmeId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    toastArchiveCallback(row) {
      if (row.is_active) {
        this.archiveProgramme(row.programmeId);
      } else {
        this.unarchiveProgramme(row.programmeId);
      }
    },

    getProgrammeArchiveToastMessage(row) {
      if (row.is_active) {
        return 'Archived programmes cannot be accessed on KATE.';
      }
      return 'Unarchived programmes are accessible on KATE.';
    },

    tableArchiveCallback(row) {
      this.$ktoast.confirmToast(this.getProgrammeArchiveToastMessage(row), 'warning', () => this.toastArchiveCallback(row), undefined);
    },

    enableKloudProgramme(programmeId) {
      this.$logger.info('Enabling kloud for programme', { programmeId }, true);
      return this.$http.put(`/api/curriculum/programmes/${programmeId}/enable-kloud`).then(() => {
        this.$logger.info('Enabled kloud for programme', { programmeId });
        this.$ktoast.success('KLOUD enabled');
        this.getProgrammes();
      }).catch(err => {
        this.$logger.error('Error enabling kloud for programme', { programmeId }, err);
        this.showError(err);
      });
    },

    disableKloudProgramme(programmeId) {
      this.$logger.info('Disabling kloud for programme', { programmeId }, true);
      return this.$http.put(`/api/curriculum/programmes/${programmeId}/disable-kloud`).then(() => {
        this.$logger.info('Disabled kloud for programme', { programmeId });
        this.$ktoast.success('KLOUD disabled');
        this.getProgrammes();
      }).catch(err => {
        this.$logger.error('Error disabling kloud for module', { programmeId }, err);
        this.showError(err);
      });
    },

    getProgrammeKloudToastMessage(row) {
      if (row.kloud.enabled) {
        return 'KLOUD will be disabled for every module and assignment.';
      }
      return 'KLOUD will be enabled for every module and assignment';
    },

    toastKloudCallback(row) {
      if (row.kloud.enabled) {
        this.disableKloudProgramme(row.programmeId);
      } else {
        this.enableKloudProgramme(row.programmeId);
      }
    },

    tableKloudCallback(row) {
      this.$ktoast.confirmToast(this.getProgrammeKloudToastMessage(row), 'warning', () => this.toastKloudCallback(row), undefined);
    },

    enableKnowledgeBaseProgramme(programmeId) {
      this.$logger.info('Enabling knowledge base for programme', { programmeId }, true);
      return this.$http.put(`/api/curriculum/programmes/${programmeId}/enable-knowledge-base`).then(() => {
        this.$logger.info('Enabled knowledge base for programme', { programmeId });
        this.$ktoast.success('Knowledge Base enabled');
        this.getProgrammes();
      }).catch(err => {
        this.$logger.error('Error enabling knowledge base for programme', { programmeId }, err);
        this.showError(err);
      });
    },

    disableKnowledgeBaseProgramme(programmeId) {
      this.$logger.info('Disabling knowledge base for programme', { programmeId }, true);
      return this.$http.put(`/api/curriculum/programmes/${programmeId}/disable-knowledge-base`).then(() => {
        this.$logger.info('Disabled knowledge base for programme', { programmeId });
        this.$ktoast.success('Knowledge Base disabled');
        this.getProgrammes();
      }).catch(err => {
        this.$logger.error('Error disabling knowledge base for programme', { programmeId }, err);
        this.showError(err);
      });
    },

    toastKnowledgeBaseCallback(row) {
      if (row.knowledgeBase.enabled) {
        this.disableKnowledgeBaseProgramme(row.programmeId);
      } else {
        this.enableKnowledgeBaseProgramme(row.programmeId);
      }
    },

    getProgrammeKnowledgeBaseToastMessage(row) {
      if (row.knowledgeBase.enabled) {
        return 'The knowledge base will be disabled for every module and assignment.';
      }
      return 'The knowledge base will be enabled for every module and assignment.';
    },

    tableKnowledgeBaseCallback(row) {
      this.$ktoast.confirmToast(this.getProgrammeKnowledgeBaseToastMessage(row), 'warning', () => this.toastKnowledgeBaseCallback(row), undefined);
    },

    tableCallback(key, index, row, column) {
      if (column === 'archive') {
        this.tableArchiveCallback(row);
      } else if (column === 'kloud') {
        this.tableKloudCallback(row);
      } else if (column === 'knowledgeBase') {
        this.tableKnowledgeBaseCallback(row);
      } else if (column === 'edit') {
        this.openUpdateProgrammeModal(row.programmeId);
      }
    },

    programmeArchiveIcon(isActive) {
      if (isActive) {
        return '<div title="Programme is live. Click to archive programme" style="text-align: center;"><button class="btn btn-warning" title="Programme is live. Click to archive programme">Archive</button></div>';
      }
      return '<div title="Programme is archived. Click to unarchive" style="text-align: center;"><button class="btn btn-primary" title="Programme is archived. Click to unarchive">Unarchive</button></div>';
    },

    enabledIcon(enabled, title) {
      if (enabled) {
        return `<div title="${title} enabled for programme. Click to disable." style="text-align: center;"><button class="btn btn-danger" title="Click to disable ${title} for programme">Disable</button></div>`;
      }
      return `<div title="${title} disabled for programme. Click to enable." style="text-align: center;"><button class="btn btn-success" title="Click to enable ${title} for programme">Enable</button></div>`;
    },

    openUpdateProgrammeModal(programmeId) {
      const prog = this.programmes.find(x => x.id === programmeId);
      if (prog && typeof prog === 'object') {
        this.programmeToUpdate = JSON.parse(JSON.stringify(prog));
      } else {
        this.programmeToUpdate = undefined;
      }
    },
  },
};
</script>
