<template>
  <div class="row">
    <div class="slides-form-container col-md-7 col-xs-12">
      <h3>Details</h3>
      <form class="slides-details">
        <fieldset class="form-group form-entry col-md-8">
          <label id="slides-title-input">Title
            <input required
              id="slides-title-input"
              type="text"
              class="form-control"
              placeholder="Type title"
              v-model="slidesTitle"
              :disabled="isUploading">
            <small>Please keep the title generic and not programme specific</small>
          </label>
        </fieldset>
        <fieldset class="form-group form-entry col-md-12 slides-description">
          <label for="slides-description-input">Description
            <textarea id="slides-description-input"
              class="form-control"
              placeholder="Enter description here..."
              rows="3"
              v-model="slidesDescription"
              :disabled="isUploading">
            </textarea>
          </label>
        </fieldset>
      </form>
      <word-tags v-model="slidesTags" textInputPlaceholder="Add a tag"></word-tags>
    </div>
    <div class="col-md-5 col-xs-12">
      <h3>Upload</h3>
      <dropfield @file="filesChange"
        ref="slidesUploadField"
        :customMessage="dropfieldMessage"
        :immediatePost="false"
        :disableDropfield="isUploading"
        accept="application/pdf"
        url=""
        id="k-add-slides-dropfield">
      </dropfield>
    </div>
    <div class="col-md-12">
      <button class="submit-button btn btn-success save-button"
        :disabled="!validForm"
        @click="uploadSlides">
        <i :class="isUploading ? 'fa fa-spinner fa-spin' : 'fas fa-upload'"></i>
        {{ isUploading ? 'Uploading...' : 'Upload Slides' }}
      </button>
      <br/>
      <small v-if="isUploading">
        <i>This may take a few minutes. Please do not navigate away from this page.</i>
      </small>
    </div>
  </div>
</template>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
}

section h2 {
  margin-top: 0;
  margin-bottom: 25px;
}

.form-group small {
  font-style: italic;
}

.slides-form-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.slides-form-container form {
  margin-left: -15px;
}

.slides-description label,
.slides-details label {
  width: 100%;
}

#k-add-slides-dropfield,
.slides-details {
  padding-top: 15px;
}

.slides-preview-modal figure {
  text-align: center;
}

.preview-slides {
  max-height: 70vh;
  width: auto;
}
</style>

<script>
import ErrorMixin from '../../../../mixins/error-mixins';
import TimeMixin from '../../../../mixins/time-mixins';
import Dropfield from '../../../../components/dropfield.vue';
import WordTags from '../../../components/word-tags.vue';

export default {
  components: {
    dropfield: Dropfield,
    'word-tags': WordTags,
  },

  mixins: [ErrorMixin, TimeMixin],

  data() {
    return {
      slidesTitle: '',
      recordedDate: null,
      slidesDescription: '',
      slidesTags: [],
      file: undefined,
      dropfieldMessage: `<i class="fas fa-cloud-upload-alt"></i>
      Click or drag slides file here to upload.`,
      isUploading: false,
    };
  },

  watch: {
    isUploading() {
      if (this.isUploading) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },

  computed: {
    validTitle() {
      return this.slidesTitle.trim().length > 0;
    },

    validForm() {
      return Boolean(this.validTitle && this.file);
    },
  },

  methods: {
    uploadSlides() {
      this.isUploading = true;
      let slidesMeta = {
        name: this.slidesTitle,
        description: this.slidesDescription,
        tags: this.slidesTags,
      };
      this.$logger.info('Adding slides to database', { slidesMeta }, true);
      this.$http.post('/api/curriculum/admin/slide_deck', slidesMeta).then(res => {
        this.$logger.info('Added slides to the database successfully', { slide_deck_id: res.data.slide_deck_id });
        const slidesId = res.data.slide_deck_id;
        const payload = {
          file_name: this.file[0].name,
          file_size: this.file[0].size,
          content_type: this.file[0].type,
        };
        // POST request to initiate resumable upload and return upload URL
        this.$logger.info('Getting signed resumable upload URL');
        this.$http.post(`/api/curriculum/admin/slide_deck/${slidesId}/upload-url`, payload)
          .then(response => {
            this.$logger.info('Got signed resumable upload URL');
            const url = response.data.upload_url;
            this.$logger.info('Attempting file upload', undefined, true);
            // PUT request to upload using signed URL
            this.$http.put(url, this.file[0]).then(() => {
              this.$logger.info('File uploaded successfully');
              slidesMeta = {
                file_name: this.file[0].name,
                name: this.slidesTitle,
                description: this.slidesDescription,
                tags: this.slidesTags,
              };
              this.$logger.info('Updating slides database entry with resource link', { slidesMeta, file: this.file.name }, true);
              this.$http.put(`/api/curriculum/admin/slide_deck/${slidesId}`, slidesMeta).then(() => {
                this.$logger.info('Added slides to the database succesfully', { slidesId });
                this.$ktoast.success('Slides uploaded');
                this.clearFields();
                this.$emit('slides-uploaded');
                this.isUploading = false;
              }).catch(err => {
                this.$logger.error('Error updating slides resource link', { slidesMeta, file: this.file.name }, err);
                this.showError(err);
                this.isUploading = false;
              });
            }).catch(err => {
              this.$logger.error('Error uploading slides with signed resumable upload URL', undefined, err);
              this.showError(err);
              this.isUploading = false;
            });
          }).catch(err => {
            this.$logger.error('Error getting signed resumable upload URL', undefined, err);
            this.showError(err);
            this.isUploading = false;
          });
      }).catch(err => {
        this.$logger.error('Error writing slides meta to database', { slidesMeta }, err);
        this.showError(err);
        this.isUploading = false;
      });
    },

    filesChange(file) {
      this.$logger.info('Staged file for upload', { file: file.name });
      this.file = file;
    },

    clearFields() {
      this.$refs.slidesUploadField.reset();
      this.slidesTitle = '';
      this.slidesDescription = '';
      this.recordedDate = null;
      this.slidesTags = [];
    },
  },
};
</script>
