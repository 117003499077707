<template>
  <div class="panel">
    <div class="controls">
      <button v-if="renderMode" class="btn btn-primary" @click="enterEditMode" aria-label="Edit" title="Edit">
        Edit Questionnaire
        <i class="fas fa-edit"></i>
      </button>
      <button v-else class="btn btn-primary" @click="enterRenderMode" aria-label="Preview" title="Preview">Preview Questionnaire</button>
    </div>
    <div v-if="questionnaire && questionnaireReady" class="panel-body">
      <div v-if="renderMode">
        <div class="heading-container">
          <div class="title-container">
            <h1>
              {{ questionnaire.name }}
              <span class="read-only">Read-Only</span>
            </h1>
            <p class="description" v-html="getMarkdown(questionnaire.description)"></p>
          </div>
        </div>
        <k-questionnaire :questionnaire="questionnaire" :read-only="true"></k-questionnaire>
      </div>
      <questionnaire-builder
        v-else
        v-model:questionnaire="questionnaire"
        @update-questionnaire="getQuestionnaire"
        @files-staged="handleFilesStaged">
      </questionnaire-builder>
    </div>
  </div>
</template>

<style scoped>
.controls {
  padding: 5px 15px;
}

h1 > .read-only {
  float: right;
  padding-right: 20px;
  font-size: 0.8em;
  font-style: italic;
  text-transform: lowercase;
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0 !important;
}

.heading-container .heading-details {
  flex: 1 1 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;
}

.title-container {
  align-self: unset;
}

#content .title-container h1 {
  margin: 0 !important;
  align-self: unset;
  padding: 10px 0;
  width: 100%;
}

</style>

<script>
import QuestionnaireBuilder from './questionnaire_creation/questionnaire-builder.vue';
import { markdown } from '../../../../modules/maths-markdown';
import KQuestionnaire from '../../../../questionnaires/k-questionnaire.vue';

export default {
  components: {
    KQuestionnaire,
    QuestionnaireBuilder,
  },

  data() {
    return {
      questionnaireReady: false,
      questionnaire: undefined,
      renderMode: false,
      hasStagedFiles: false,
      showConfirmationModal: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getQuestionnaire();
  },

  watch: {
    questionnaireReady() {
      if (this.questionnaireReady) {
        this.$Loading.finish();
      } else {
        this.$Loading.start();
      }
    },
  },

  computed: {
    questionnaireId() {
      return parseInt(this.$route.params.questionnaireId, 10);
    },
  },

  methods: {
    registerCrumbs() {
      this.$crumbs.register([
        {
          text: 'Questionnaire Overview',
          path: {
            name: 'curriculum_assets_questionnaire',
          },
        },
        {
          text: this.questionnaire.name || this.questionnaire.id,
          active: true,
        },
      ]);
    },
    enterEditMode() {
      this.renderMode = false;
    },
    enterRenderMode() {
      this.renderMode = true;
    },
    getQuestionnaire() {
      this.questionnaireReady = false;
      this.$logger.info('Getting questionnaire', { questionnaireId: this.questionnaireId }, true);
      this.$http.get(`/api/curriculum/admin/questionnaire/${this.questionnaireId}`).then(response => {
        const resp = response.data.questionnaire;
        this.questionnaire = {
          ...resp,
          prompts: resp.prompts ? resp.prompts.map(p => ({
            id: p.id,
            prompt: p.prompt,
            responseType: p.response_type,
            reviewType: p.review_type,
            number: p.number,
            answers: p.answers,
          })) : [],
        };
        this.$logger.info('Got questionnaire', { questionnaireId: this.questionnaireId });
        this.registerCrumbs();
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          this.$logger.warn('Questionnaire not found', { questionnaireId: this.questionnaireId }, err);
        } else if (this.$http.isWarning(err)) {
          this.$logger.warn('Error getting questionnaire', { questionnaireId: this.questionnaireId }, err);
          this.showError(err, true);
        } else {
          this.$logger.error('Error getting questionnaire', { questionnaireId: this.questionnaireId }, err);
          this.showError(err, true);
        }
      }).then(() => {
        this.questionnaireReady = true;
      });
    },

    getMarkdown(str) {
      return markdown(str);
    },

    handleFilesStaged(payload) {
      this.hasStagedFiles = payload;
    },
  },
};
</script>
