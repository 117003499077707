<template>
  <fieldset v-if="type !== 'component'" class="form-entry" :class="[{'form-required': required}, {'full-width': fullWidth}, {'form-warning': showWarning}]">
    <label :for="inputId">{{ label }}</label>
    <input v-if="type !== 'textarea'"
      :id="inputId"
      :type="type"
      class="form-control"
      :placeholder="placeholder"
      v-model="value"
      :maxlength="maxLength">
    <textarea v-if="type === 'textarea'"
      :id="inputId"
      class="form-control"
      :placeholder="placeholder"
      v-model="value"
      rows="2"
      :maxlength="maxLength"></textarea>
    <span v-if="showWarning" class="input-warning">
      <i class="fa-solid fa-triangle-exclamation"></i> {{ warningMessage }}
    </span>
  </fieldset>
  <fieldset v-else class="form-entry" :class="{'full-width': fullWidth}">
    <legend v-if="label">{{ label }}</legend>
    <slot name="components">
    </slot>
  </fieldset>
</template>

<style scoped>
.full-width {
  width: 100%;
}

label,
legend {
  color: var(--kate-type-light);
  font-size: 1.1rem;
  border: 0;
  display: inline-block;
  margin-bottom: 5px;
}

/* Remove unwanted styling when clicking autofill */
input.form-control:-webkit-autofill,
input.form-control:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px var(--input-background) inset;
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

input {
  font-weight: 700;
}

.input-warning {
  margin-top: 5px;
  display: block;
}

.input-warning i {
  color: var(--kate-warning);
}

.form-warning input {
  border: 1px solid var(--kate-danger);
}
</style>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    inputId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: 'Enter text here',
    },
    modelValue: {
      type: [String, Number, Boolean, Array],
      default: '',
    },
    maxLength: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    showWarning: {
      type: Boolean,
      default: false,
    },
    warningMessage: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      value: this.modelValue,
    };
  },

  watch: {
    modelValue() {
      this.value = this.modelValue;
    },
    value() {
      this.$emit('update:modelValue', this.value);
    },
  },
};
</script>
